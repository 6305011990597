import { Col, Row } from 'components-styled/Grid';
import styled from 'styled-components';
import { ReactComponent as Icon } from 'common/src/icons/corona.svg';
import { Button } from 'components-styled/Button';
import { useAppSelector } from "common/src/hooks";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export default function Plan() {
  const user = useAppSelector((s) => s.user.user);

  const onManageSubscription = () => {
    window.open('https://billing.stripe.com/p/login/aEU15Cf9Q1WcfXq7ss', '_blank');
  }

  return (
    <Container
      className='w-100 mx-auto flex-grow-1'
      style={{
        paddingBottom: 275,
      }}
    >
      <Row className='row'>
        <Col className='col pt-4'>
          <Icon className='mb-2' />
          <Title className='mb-2 text-uppercase fw-800'>{user.isPremium ? 'Premium Plan' : 'Upgrade to Pro'}</Title>
          <div className='text-gray mb-4'>
            Don't miss out on an opportunity to save money!
          </div>
          {
            user.isPremium &&
            <Button type='button' onClick={onManageSubscription}>Manage Subscription</Button>
          }

        </Col>
        <Col
          className='col'
          style={{
            paddingTop: 0,
          }}
        >
          {!user.isPremium &&
            <Card className='w-100 px-3 py-4'>
              <stripe-pricing-table
                pricing-table-id="prctbl_1MS3ZmG7qGZPU18GIZ0GLc59"
                publishable-key="pk_live_51MJdFFG7qGZPU18GrHnoAP73WKr27l1j7ogBPj6En7R94mSCQAhmf3XqNZN8UzOfMlYX9aT6AxTvQMO8Dg5lPrvr00tzFBhRXJ">
              </stripe-pricing-table>
            </Card>
          }
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  max-width: 900px;
  padding: 100px 25px 0;
`;

const Title = styled.div`
  font-size: 40px;
  line-height: 51px;
`;

const Card = styled.div`
  background: #ffffff;
  border: 1px solid #ebeaeb;
  border-radius: 10px;
  max-width: 449px;
`;

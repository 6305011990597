import { useEffect, useState } from "react";
import CreateDebt from "./CreateDebt";
import CreateGoal, { CreateGoalFormValues } from "./CreateGoal";
import ItemCreated from "./ItemCreated";
import { ReactComponent as DebtIcon } from "common/src/icons/debt.svg";
import ConnectAccount from "./ConnectAccount";
import { useAppDispatch, useAppSelector } from "common/src/hooks";
import {
  finishOnboarding,
  updateUserDetails,
} from "common/src/store/user/userSlice";
import Welcome from "pages/Welcome";
import apiClient from "../../core/apiClient";

export type StepType =
  | "create-goal"
  | "goal-created"
  | "create-debt"
  | "debt-created"
  | "welcome"
  | "connect-account"
  | "edit-debt";

export default function Onboarding() {
  const [step, setStep] = useState<StepType>("welcome");
  const user = useAppSelector((s) => s.user.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user.email && !user.id) {
      apiClient
        .getUser()
        .then((res) => {
          dispatch(
            updateUserDetails({
              email: res.email,
              id: res.id,
              referrer_code: res.referrer_code,
              referal_number: res.referal_number,
              is_waitlisted: res.is_waitlisted,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const renderStep = () => {
    switch (step) {
      case "welcome":
        return <Welcome onClick={() => setStep("create-goal")} />;

      case "connect-account":
        return <ConnectAccount onClick={() => setStep("create-debt")} />;

      case "create-debt":
        return (
          <CreateDebt
            onClickSave={() => setStep("debt-created")}
            onClickSkip={() => setStep("connect-account")}
          />
        );

      case "debt-created":
        return (
          <ItemCreated
            addBtnLabel="Add Another Debt"
            title="Something about adding a debt"
            subTitle="It is going to feel GREAT paying off your debt.  Diane will help prioritize what to pay off first for you."
            onClickAdd={() => setStep("create-debt")}
            onClickNext={() => dispatch(finishOnboarding())}
            icon={<DebtIcon />}
          />
        );

      case "goal-created":
        return (
          <ItemCreated
            addBtnLabel="Add Another Goal"
            title="Great work Setting a Goal"
            subTitle={`Do you have more goals that you want to have solved?  If so, add another goal below! 
            If you want to focus on one goal at a time, let’s head to the next step.`}
            onClickAdd={() => setStep("create-goal")}
            onClickNext={() => setStep("connect-account")}
          />
        );

      case "create-goal":
      default:
        return <CreateGoal onClickNext={() => setStep("goal-created")} />;
    }
  };

  return renderStep();
}

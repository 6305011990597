import { createSlice } from "@reduxjs/toolkit";

interface UserState {
  user: {
    isLoggedIn: boolean;
    id: string;
    email: string;
    referrer_code: string;
    isWaitListed: boolean;
    referralNumber: number;
    isPremium: boolean;
    subscribedTill: string;
    subscribedSource: string,
    appleOrderId: string,
  };
  fresh: boolean;
  onboarding: boolean;
}

const initialState: UserState = {
  user: {
    isLoggedIn: false,
    isWaitListed: false,
    id: "",
    email: "",
    referrer_code: "",
    referralNumber: 0,
    isPremium: false,
    subscribedTill: "",
    subscribedSource: '',
    appleOrderId: '',
  },
  fresh: false,
  onboarding: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginDispatch: (state) => {
      state.user = {
        ...state.user,
        isLoggedIn: true,
      };
    },
    logoutUser: (state) => {
      state.user = {
        isLoggedIn: false,
        isWaitListed: false,
        id: "",
        email: "",
        referrer_code: "",
        subscribedTill: "",
        referralNumber: 0,
        isPremium: false,
        subscribedSource: '',
        appleOrderId: '',
      };
      state.fresh = true;
      state.onboarding = true;
    },
    updateUserDetails: (state, action) => {
      state.user = {
        ...state.user,
        id: action.payload.id,
        email: action.payload.email,
        referrer_code: action.payload.referrer_code,
        isWaitListed: action.payload.is_waitlisted,
        referralNumber: action.payload.referal_number,
        isPremium: action.payload.is_premium,
        subscribedTill: action.payload.subscribed_till,
        subscribedSource: action.payload.subscribed_source,
        appleOrderId: action.payload.apple_order_id,
      };
    },
    finishOnboarding: (state) => {
      state.onboarding = false;
    },
    unFinishOnboarding: (state) => {
      state.onboarding = true;
    },
    freshUser: (state) => {
      state.fresh = true;
    },
    unfreshUser: (state) => {
      state.fresh = false;
    },
  },
});

export const {
  loginDispatch,
  finishOnboarding,
  unFinishOnboarding,
  unfreshUser,
  updateUserDetails,
  logoutUser,
  freshUser,
} = userSlice.actions;

export default userSlice.reducer;

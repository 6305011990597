import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  SignUpCommand,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand,
  VerifySoftwareTokenCommand,
  AssociateSoftwareTokenCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import jwtDecode, { JwtPayload } from "jwt-decode";
export const API_KEY_HEADER = "x-api-key";

const REDIRECT_URI = process.env.REACT_APP_CALLBACK_URL;
const HOSTED_UI_DOMAIN = process.env.REACT_APP_UI_DOMAIN;

export const IDENTITYPOOL_ID = process.env.REACT_APP_IDENTITY_ID;
export const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
export const S3_URL = process.env.REACT_APP_S3_BUCKET_URL;
export const UserPoolId = process.env.REACT_APP_USERPOOL_ID;
export const Region = process.env.REACT_APP_REGION;
export const ClientId = process.env.REACT_APP_USERPOOL_CLIENT_ID;
export const REFRESH_TOKEN_KEY = `refresh_token_${process.env.NODE_ENV}`;
export const ACCESS_TOKEN_KEY = `access_token_${process.env.NODE_ENV}`;
export const ID_TOKEN_KEY = `id_token_${process.env.NODE_ENV}`;
export const AUTHORIZATION_LINK = `${HOSTED_UI_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${REDIRECT_URI}&response_type=TOKEN&client_id=${ClientId}&scope=email openid profile`;

export interface LoginModel {
  email: string;
  password: string;
}

export interface ForgotPasswordConfirmModel {
  email: string;
  password: string;
  code: string;
}

export const client = new CognitoIdentityProviderClient({
  region: "us-east-2",
});

export const register = async ({ email, password }: LoginModel) =>
  await client.send(
    new SignUpCommand({
      ClientId,
      Password: password,
      Username: email,
      UserAttributes: [
        {
          Name: "name",
          Value: "Test name",
        },
        {
          Name: "email",
          Value: email,
        },
      ],
    })
  );

export const login = async ({ email, password }: LoginModel) =>
  await client.send(
    new InitiateAuthCommand({
      AuthFlow: "USER_PASSWORD_AUTH",
      AuthParameters: {
        USERNAME: email,
        PASSWORD: password,
      },
      ClientId,
    })
  );

export const forgotPassword = async (email: string) =>
  await client.send(
    new ForgotPasswordCommand({
      ClientId,
      Username: email,
    })
  );

export const associateSoftwareToken = async (session: string | undefined) => {
  return await client.send(
    new AssociateSoftwareTokenCommand({
      Session: session,
      // AccessToken: accessToken,
    })
  );
}

export const verifySoftwareToken = async (session: string, code: string) => {
  return await client.send(
    new VerifySoftwareTokenCommand({
      UserCode: code,
      Session: session
    })
  );
}

export const confirmForgotPassword = async ({ email, code, password }: ForgotPasswordConfirmModel) =>
  await client.send(
    new ConfirmForgotPasswordCommand({
      ClientId,
      Username: email,
      ConfirmationCode: code,
      Password: password,
    })
  );

export const refreshAccessToken = async (refreshToken: string) =>
  await client.send(
    new InitiateAuthCommand({
      AuthFlow: "REFRESH_TOKEN_AUTH",
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
      ClientId,
    })
  );

export const removeTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(ID_TOKEN_KEY);
};

export function isTokenInvalid(tokenStr: string): boolean {
  if (null || !tokenStr.length) {
    return false;
  } else {
    try {
      const token: JwtPayload = jwtDecode(tokenStr);
      return token.exp && Date.now() >= token.exp * 1000 ? false : true;
    } catch (error) {
      return true;
    }
  }
}

export function setTokensToLocalStorage(
  accessToken: string,
  refreshToken: string,
  idToken: string
) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  localStorage.setItem(ID_TOKEN_KEY, idToken);
}

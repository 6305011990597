import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Goals } from 'common/src/icons/sidebar/goals.svg';
import { ReactComponent as Metrics } from 'common/src/icons/sidebar/metrics.svg';
import { ReactComponent as Plan } from 'common/src/icons/sidebar/plan.svg';
import { ReactComponent as Profile } from 'common/src/icons/sidebar/profile.svg';
import { ReactComponent as Logo } from 'common/src/icons/logo-sm.svg';
import { ReactComponent as Upgrade } from 'common/src/icons/upgrade.svg';
import { ReactComponent as Logout } from 'common/src/icons/right-from-bracket-solid.svg';
import { RoutesEnum } from 'pages/DashboardRoutes';
import mixpanel from 'utils/mixpanel';

export default function Sidebar() {
  const LINKS = [
    {
      to: '/',
      label: 'Goals',
      icon: <Goals />,
    },
    {
      to: RoutesEnum.metrics,
      label: 'Key Metrics',
      icon: <Metrics />,
      onClick:()=> mixpanel.track("Clicked Metrics")
    },
    {
      to: RoutesEnum.yourPlan,
      label: 'Your Plan',
      icon: <Plan />,
      onClick:()=> mixpanel.track("Clicked Your Plan")
    },
    {
      to: RoutesEnum.profile,
      label: 'Profile',
      icon: <Profile />,
      onClick:()=> mixpanel.track("Clicked Profile")
    },
    {
      to: RoutesEnum.proPlan,
      label: 'Upgrade to Pro',
      icon: <Upgrade className='svg-duotone' />,
      onClick:()=> mixpanel.track("Clicked Pro Plan")
    },
  ];

  const onLogout = () => {
    localStorage.clear();
    window.location.href = '/';
  }

  return (
    <Container>
      <Content>
        <div
          className='sticky-top'
          style={{
            top: 40,
          }}
        >
          <Link to='/'>
            <Logo />
          </Link>
          <div style={{ marginTop: 80 }}>
            {LINKS.map((l) => (
              <div key={l.to}>
                <SidebarLink
                  end
                  to={l.to}
                  onClick={() => {
                    l.onClick?.();
                  }}
                  className='text-decoration-none fw-800 d-flex align-items-center transition-color'
                // "route" | "path"
                >
                  {l.icon}
                  {l.label}
                </SidebarLink>
              </div>
            ))}
          </div>
          <LogoutButton onClick={onLogout} className='text-decoration-none fw-800 d-flex align-items-center transition-color'>
            <Logout />
            Logout
          </LogoutButton>
        </div>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 262px;
`;

const Content = styled(Container)`
  background: #100840;
  height: 100%;
  padding: 40px;
  z-index: 1;
`;

const SidebarLink = styled(NavLink)`
  color: #8d89a3;
  font-size: 18px;
  line-height: 17px;
  margin-bottom: 50px;

  svg:not(.svg-duotone) {
    margin-right: 15px;
    rect {
      fill: #8d89a3;
      transition: fill 0.25s;
    }
    path {
      fill: #8d89a3;
      transition: fill 0.25s;
    }
  }

  &:hover,
  &.active {
    color: #ffffff;
    svg:not(.svg-duotone) {
      path {
        fill: #ffffff;
      }
      rect {
        fill: #ffffff;
      }
    }
  }
`;

const LogoutButton = styled.a`
  color: #8d89a3;
  font-size: 18px;
  cursor: pointer;
  svg {
    width: 43px;
    padding-left: 3px;
    margin-right: 15px;
    rect {
      fill: #8d89a3;
      transition: fill 0.25s;
    }
    path {
      fill: #8d89a3;
      transition: fill 0.25s;
    }
  }
  &:hover {
    color: #ffffff;
    svg {
      path {
        fill: #ffffff;
      }
      rect {
        fill: #ffffff;
      }
    }
  }
`;
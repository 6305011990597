import { Routes, Route, RouteProps, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import "./backgrounds.css";
import "./index.scss";
import { useScrollTopOnRouteChange } from "./hooks/useScrollTopOnRouteChange";
import Onboarding from "./pages/Onboarding/Onboarding";
import {
  useAppDispatch,
  useAppSelector,
} from "common/src/hooks/useReduxToolkit";
import DashboardRoutes from "pages/DashboardRoutes";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import apiClient from "core/apiClient";
import {
  finishOnboarding,
  loginDispatch,
  unfreshUser,
} from "common/src/store/user/userSlice";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { isTokenInvalid } from "common/src/auth";
import { getTokens } from "core/auth";
import { updateGoals } from "common/src/store/goals/goalsSlice";

const publicRoutes: (RouteProps & {
  redirect?: boolean;
})[] = [
  {
    element: <Login />,
    path: "/",
  },
  {
    redirect: true,
    path: "/",
  },
];

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const userSlice = useAppSelector((s) => s.user);
  const dispatch = useAppDispatch();
  const tokens = getTokens();
  useScrollTopOnRouteChange();

  useEffect(() => {
    if (isTokenInvalid(tokens.accessToken ?? "")) {
      dispatch(loginDispatch());
      apiClient
        .getGoals()
        .then((res) => {
          if (res.length > 0) {
            dispatch(updateGoals(res));
            dispatch(finishOnboarding());
            dispatch(unfreshUser());
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const authedRoutes: (RouteProps & {
    redirect?: boolean;
  })[] = [
    ...(!userSlice.fresh && !userSlice.onboarding
      ? [
        ]
      : []),
    {
      element: userSlice.onboarding ? <Onboarding /> : <DashboardRoutes />,
      path: userSlice.onboarding ? "/" : "/*",
    },
  ];

  return (
    <>
      {isLoading ? (
        <CircleSpinnerOverlay
          loading={isLoading}
          overlayColor="rgba(16, 8, 64, 0.2)"
          color="rgb(16, 8, 64)"
        />
      ) : (
        <Routes>
          {(userSlice.user.isLoggedIn ? authedRoutes : publicRoutes).map(
            ({ redirect, path, ...rest }) => {
              if (redirect) {
                return (
                  <Route
                    path="*"
                    element={<Navigate to={path || "/"} replace />}
                    key={path}
                  />
                );
              }
              return <Route {...rest} key={path} path={path} />;
            }
          )}
        </Routes>
      )}
    </>
  );
}

export default App;

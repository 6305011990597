import axios from "axios";
import { API } from "common/src/api";
import { getTokens } from "./auth";

axios.interceptors.request.use(
  async function (config: any) {
    config.headers = {
      ...config.headers,
      "x-api-key": process.env.REACT_APP_SHARED_SECRET_API_KEY!,
    };
    const tokens = getTokens();

    if (tokens.accessToken && tokens.idToken) {
      // config.headers['x-access-token'] = tokens.accessToken;
      config.headers["x-id-token"] = tokens.idToken;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.href = '/';
  }
  return Promise.reject(error);
});

export default new API(axios);

import { ACCESS_TOKEN_KEY, ID_TOKEN_KEY } from "common/src/auth";

export const TOKEN_KEY = "access_token";

export function getTokens() {
  return {
    accessToken: localStorage.getItem(ACCESS_TOKEN_KEY),
    idToken: localStorage.getItem(ID_TOKEN_KEY),
  };
}

import "./Footer.scss";
import { ReactComponent as Logo } from "common/src/icons/logo-gray.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="Footer d-flex justify-content-between align-items-center text--14">
      <Link to="/">
        <Logo />
      </Link>
      <div>
        {/* <div className='mb-2'>footerinformation</div> */}
        <div className="mb-2">
          <a
            href="mailto:moneyhelp@dianemoney.com"
            className="text-decoration-none"
          >
            moneyhelp@dianemoney.com
          </a>
        </div>
        <div>
          <a
            href="https://app.termly.io/document/terms-of-use-for-mobile-app/259d1615-e6d5-46fb-abb3-7281f602ffd7"
            target="_blank"
            className="text-decoration-none"
          >
            Terms Of Service
          </a>
        </div>
        <div>
          <a
            href="https://app.termly.io/document/privacy-policy/b2dfe2fe-9438-42cc-8b58-e8e10e007509"
            target="_blank"
            className="text-decoration-none"
          >
            Privacy Policy
          </a>
        </div>
        <div>
          <a
            href="https://forms.monday.com/forms/a648eb2e2ca0aa4ebe0062cbb23dec06?r=use1"
            target="_blank"
            className="text-decoration-underline"
          >
            Report an Issue
          </a>
        </div>
      </div>
    </div>
  );
}

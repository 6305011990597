import classNames from 'classnames';
import { ChangeEventHandler } from 'react';
import './Switch.scss';

interface Props {
  checked: boolean;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  id?: string;
  name?: string;
  label: string;
  className?: string;
}

export default function Switch({
  checked,
  disabled,
  onChange,
  id,
  name,
  label,
  className = '',
}: Props) {
  return (
    <div className={`d-flex align-items-center ${className}`}>
      <label
        className={classNames('Switch me-3', {
          'Switch--disabled': disabled,
        })}
      >
        <input
          type='checkbox'
          id={id}
          name={name}
          data-testid={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <span className='Switch__slider' />
      </label>
      <div className='ms-n1'>{label}</div>
    </div>
  );
}

import { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronRight } from 'common/src/icons/chevron-right.svg';

export default function LinkButton({
  children,
  className,
  ...rest
}: PropsWithChildren<
  { className?: string } & AnchorHTMLAttributes<HTMLAnchorElement>
>) {
  return (
    <StyledButton
      {...rest}
      className={`d-inline-flex align-items-center ${className || ''}`}
    >
      {children}
      <span
        style={{
          marginLeft: 7,
        }}
      >
        <ChevronRight />
      </span>
    </StyledButton>
  );
}

const StyledButton = styled.a`
  outline: none;
  background: transparent;
  padding: 0 0 7px 0;
  font-weight: 800;
  font-size: 16px;
  line-height: 17px;
  color: #5959e3;
  border: none;
  position: relative;
  cursor: pointer;
  transition: color 0.25s;

  svg {
    path {
      fill: #5959e3;
      transition: fill 0.25s;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    background-color: #5959e3;
    width: calc(100% - 15px);
    transition: background-color 0.25s;
  }

  &:hover {
    color: #353192;

    svg {
      path {
        fill: #353192;
      }
    }
    &:after {
      background-color: #353192;
    }
  }
  &:focus {
    color: #100840;

    svg {
      path {
        fill: #100840;
      }
    }
    &:after {
      background-color: #100840;
    }
  }
`;

import { createSlice } from "@reduxjs/toolkit";

type DebtType = {
  id: number;
  name: string;
  interest_rate: number;
  total_amount: number;
  amount: number;
  total_paid: number;
  monthly_payment: number;
  type: string;
  report?: any;
};

interface DebtsState {
  debts: DebtType[];
  editDebt: {
    id: number;
    name: string;
    interest_rate: number;
    total_amount: number;
    amount: number;
    total_paid: number;
    monthly_payment: number;
    type: string;
  };
}

const initialState: DebtsState = {
  debts: [],
  editDebt: {
    id: 0,
    name: "",
    interest_rate: 0,
    total_amount: 0,
    amount: 0,
    total_paid: 0,
    monthly_payment: 0,
    type: "",
  },
};

export const debtsSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateDebts: (state, action) => {
      state.debts = action.payload;
    },
    setEditDebt: (state, action) => {
      state.editDebt = {
        ...state.editDebt,
        id: action.payload.id,
        name: action.payload.name,
        interest_rate: action.payload.interest_rate,
        total_amount: action.payload.total_amount,
        amount: action.payload.amount,
        total_paid: action.payload.total_paid,
        monthly_payment: action.payload.monthly_payment,
        type: action.payload.type,
      };
    },
  },
});

export const { setEditDebt, updateDebts } = debtsSlice.actions;

export default debtsSlice.reducer;

import { useState } from "react";

import {
  AUTHORIZATION_LINK,
  register,
  login,
  forgotPassword,
  confirmForgotPassword,
  setTokensToLocalStorage,
  isTokenInvalid,
  associateSoftwareToken,
  verifySoftwareToken,
} from "common/src/auth";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
import { useFormik, FormikErrors } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/** Presentational */
import { ReactComponent as Logo } from "common/src/icons/logo.svg";
import { ReactComponent as Google } from "common/src/icons/google.svg";
import { Button } from "components-styled/Button";
import Input from "../components/Input/Input";
import Checkbox from "components/Checkbox/Checkbox";

/** containers */
import LoginContainer from "../containers/LoginContainer";
import { LoginCard } from "../components-styled/Card";

/** redux */
import { useAppDispatch } from "common/src/hooks/useReduxToolkit";
import {
  finishOnboarding,
  loginDispatch,
  unFinishOnboarding,
  unfreshUser,
} from "common/src/store/user/userSlice";

/** helper methods */
import {
  containsCapital,
  containsDigit,
  containsNonCapital,
  containsSpecialCharacter,
  isPasswordLongEnough,
  noSpacesOnEnds,
} from "common/src/helpers";
import { getEmailFromToken } from "../helpers";

import apiClient from "core/apiClient";
import { updateGoals } from "common/src/store/goals/goalsSlice";
import mixpanel from "utils/mixpanel";

import MFA from "./MFA";

import "./Login.scss";

// import * as YUP from 'yup'

const queryString = require("query-string");

const BUTTONS: {
  label: string;
  icon: JSX.Element;
  href: string;
  borderColor?: string;
  iconMarginRight: string;
  type?: string;
}[] = [
    {
      label: "Login via Google",
      icon: <Google />,
      href: AUTHORIZATION_LINK,
      iconMarginRight: "8px",
    },
  ];

interface FormikValues {
  email: string;
  password: string;
}

export default function Login() {
  const [isRegistering, setIsRegistering] = useState(false);
  const [isPassForgot, setIsPassForgot] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isMfaChallenge, setIsMfaChallenge] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [session, setSession] = useState('');
  const location = useLocation();
  const dispatch = useAppDispatch();
  const params = queryString.parse(location.hash);

  const privacyPolicy = 'https://app.termly.io/document/privacy-policy/b2dfe2fe-9438-42cc-8b58-e8e10e007509';
  const termsConditions = 'https://app.termly.io/document/terms-of-use-for-mobile-app/259d1615-e6d5-46fb-abb3-7281f602ffd7';

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      email: "",
      password: "",
      is_accepted: false,
    },
    validate: (values) => {
      const errors: FormikErrors<FormikValues> = {};
      if (!values.email) {
        errors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = "Invalid email address";
      }
  
      if (!values.password) {
        errors.password = "Password is required";
      } else if (values.password.length < 8) {
        errors.password = "Password must be at least 8 characters";
      } else if (!/(?=.*[a-z])/.test(values.password)) {
        errors.password = "Password must contain at least one lowercase letter";
      } else if (!/(?=.*[A-Z])/.test(values.password)) {
        errors.password = "Password must contain at least one uppercase letter";
      } else if (!/(?=.*[0-9])/.test(values.password)) {
        errors.password = "Password must contain at least one number";
      } else if (!/(?=.*[!@#$%^&*])/.test(values.password)) {
        errors.password = "Password must contain at least one special character";
      }

  
      return errors;
    },

    onSubmit: (values) => {
      setIsSubmitting(true);
      if (isRegistering) {
        if (!values.is_accepted) {
          toast.error(
            "Please accept Terms of Service and Privacy Policy",
            {
              position: "top-center",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );      
          setIsSubmitting(false);
          return
        }
        register(values)
          .then((signUpRes) => {
            if (signUpRes.UserConfirmed) {
              login(values)
                .then((res) => {
                  if (res.AuthenticationResult) {
                    setTokensToLocalStorage(
                      res.AuthenticationResult?.AccessToken ?? "",
                      res.AuthenticationResult.RefreshToken ?? "",
                      res.AuthenticationResult.IdToken ?? ""
                    );
                    const { IdToken = "" } = res.AuthenticationResult;
              
                    return apiClient.confirmSignUp(values.email, IdToken, "");
                  }
                })
                .then(() => {
                  mixpanel.track("Sign up")
                  setIsRegistering(false);
                  
                  return toast.success(
                    "Account created successfully! You can now login",
                    {
                      position: "top-center",
                      autoClose: 6000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "dark",
                    }
                  );

                })
                .catch((err) => {
                  console.error(err?.response?.data)
                  console.error(err);
                  toast.error(err?.response?.data || err.message, {
                    position: "top-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                }).finally(() => {
                  setIsSubmitting(false);
                });
              } else {
              setIsRegistering(false);
            }
          })
          .catch((err) => {
            setIsSubmitting(true);
            return toast.error(err.message, {
              position: "top-center",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          });
      } else {
        login(values)
          .then(async (res) => {
            if (res.AuthenticationResult) {
              setTokensToLocalStorage(
                res.AuthenticationResult?.AccessToken ?? "",
                res.AuthenticationResult.RefreshToken ?? "",
                res.AuthenticationResult.IdToken ?? ""
              );
            } else if (res.Session) {
              // setSession(res.Session)
              setIsMfaChallenge(true)
            }
            associateSoftwareToken(res?.Session).then(res => {
              setSession(res.Session || '')
            })
          })
          .then(() => {
            mixpanel.track("Login")

            dispatch(finishOnboarding());
            dispatch(unfreshUser());
            dispatch(loginDispatch());

            apiClient
              .getGoals()
              .then((res) => {
                if (res.length > 0) {
                  // dispatch(finishOnboarding());
                  // dispatch(unfreshUser());
                  dispatch(updateGoals(res));
                  // dispatch(loginDispatch());
                } else {
                  dispatch(unFinishOnboarding());
                  // dispatch(loginDispatch());
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            return toast.error(err.message, {
              position: "top-center",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }).finally(() => {
            setIsSubmitting(false);
          });
      }
    },
  });

  const formik2 = useFormik({
    validateOnBlur: true,
    initialValues: {
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
    },
    validate: (values) => {
      const errors: FormikErrors<FormikValues> = {};
      return errors;
    },

    onSubmit: (values) => {
      confirmForgotPassword(values)
        .then((res) => {
          setIsRegistering(false);
          setIsPassForgot(false);
          return toast.success(
            "Password reset successfully! You can now login",
            {
              position: "top-center",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        })
        .catch((err) => {
          return toast.error(err.message, {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    },
  });

  const requestResetCode = () => {
    forgotPassword(formik2.values.email)
      .then((res) => {
        setIsCodeSent(true)
        return toast.success(
          "Reset code sent to email!",
          {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      })
      .catch((err) => {
        return toast.error(err.message, {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  }

  const verifyMfa = (code: string) => {
    verifySoftwareToken(session, code)
      .then((res) => {
      })
      .catch((err) => {
        return toast.error(err.message, {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  }

  if (params.id_token && isTokenInvalid(params.access_token)) {
    setTokensToLocalStorage(params.access_token, "", params.id_token);
    const email = getEmailFromToken(params.id_token);

    if (params.referrer_code) {
      // // confirm user if it's first time sign up
      apiClient
        .confirmSignUp(email, params.id_token, params.referrer_code)
        .then((res) => {
          window.location.href = "/";
          dispatch(loginDispatch());
        })
        .catch((err) => {
          // user already confirmed, redirect
          window.location.href = "/";
          dispatch(loginDispatch());
        });
    } else {
      apiClient
        .confirmSignUp(email, params.id_token, "")
        .then((res) => {
          window.location.href = "/";
          dispatch(loginDispatch());
        })
        .catch((err) => {
          // user already confirmed, redirect
          window.location.href = "/";
          dispatch(loginDispatch());
        });
    }
  }

  return (
    <LoginContainer>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isPassForgot ?
        <LoginCard className="pt-2 pb-5 px-3 mx-auto mt-n2 text-center">
          <Logo />
          <div className="text-gray fw-800 mb-2">
            Forgot Password
          </div>
          <form
            className="d-flex flex-column align-items-center"
            onSubmit={formik2.handleSubmit}
          >
            <Input
              name="email"
              value={formik2.values.email}
              onChange={formik2.handleChange}
              placeholder="Email"
              className="mb-2 w-100 Input--Login"
              error={formik2.touched.email && formik2.errors.email}
              onBlur={formik2.handleBlur}
            />
            {
              !isCodeSent &&
              <Button type="button" onClick={requestResetCode} className="mb-3">
                Submit
              </Button>
            }
            {
              isCodeSent &&
              <>
                <Input
                  name="code"
                  value={formik2.values.code}
                  onChange={formik2.handleChange}
                  placeholder="Code"
                  className="mb-2 w-100 Input--Login"
                />
                <Input
                  name="password"
                  value={formik2.values.password}
                  onChange={formik2.handleChange}
                  type="password"
                  placeholder="Password"
                  className="mb-2 w-100 Input--Login"
                  error={formik2.touched.password && formik2.errors.password}
                  onBlur={formik2.handleBlur}
                />
                <Input
                  name="confirmPassword"
                  value={formik2.values.confirmPassword}
                  onChange={formik2.handleChange}
                  type="password"
                  placeholder="Confirm Password"
                  className="mb-3 w-100 Input--Login"
                  error={(formik2.touched.password || formik2.touched.confirmPassword) && (formik2.errors.confirmPassword || (formik2.values.password !== formik2.values.confirmPassword))}
                  onBlur={formik2.handleBlur}
                />
                <Button type="submit" className="mb-3">
                  Submit
                </Button>
              </>
            }
          </form>
          <div className="mb-3">
            {isRegistering ? "Have account?" : "No account?"}&nbsp;
            <button
              type="button"
              className="p-0 bg-transparent transition-color border-0 Login__signUp text-decoration-underline cursor-pointer"
              onClick={() => {
                setIsPassForgot(false)
                setIsRegistering((p) => !p);
              }}
            >
              {isRegistering ? "Login" : "Sign Up"}
            </button>
          </div>
        </LoginCard> :
        <LoginCard className="pt-2 pb-5 px-3 mx-auto mt-n2 text-center">
          <Logo />
          <div className="text-gray fw-800 mb-2">
            {isRegistering ? "Sign Up" : "Login"}
          </div>
          {/* <div className="mb-2 d-flex flex-column align-items-center">
            {BUTTONS.map((b) => (
              <SocialButton
                borderColor={b.borderColor}
                iconMarginRight={b.iconMarginRight}
                href={b.href}
                key={b.label}
                className="w-100 mb-2 d-flex align-items-center justify-content-center text-decoration-none"
              >
                {b.icon}
                {b.label}
              </SocialButton>
            ))}
          </div>
          <div className="text-gray fw-800 mb-2">Or</div> */}
          <form
            className="d-flex flex-column align-items-center"
            onSubmit={formik.handleSubmit}
          >
            <Input
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="Email"
              className="mb-2 w-100 Input--Login"
              error={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
            />
            <Input
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              type="password"
              placeholder="Password"
              className="mb-3 w-100 Input--Login"
              error={formik.touched.password && formik.errors.password}
              onBlur={formik.handleBlur}
            />
            {isRegistering &&
              <Checkbox
                checked={formik.values.is_accepted}
                onChange={(e) => {
                  formik.setFieldValue("is_accepted", e.target.checked);
                }}
                label={<span>I accept the <a href={termsConditions} target="_blank" rel="noreferrer">Terms of Service</a> and <a href={privacyPolicy} target="_blank" rel="noreferrer">Privacy Policy</a>.</span>}
                className="mb-4"
              />
            }
            <Button type="submit" className="mb-3" disabled={isSubmitting}>
              {isSubmitting ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : isRegistering ? "Sign Up" : "Login"}
            </Button>
            <div className="mb-3">
              {isRegistering ? "Have account?" : "No account?"}&nbsp;
              <button
                type="button"
                className="p-0 bg-transparent transition-color border-0 Login__signUp text-decoration-underline cursor-pointer"
                onClick={() => {
                  setIsRegistering((p) => !p);
                }}
              >
                {isRegistering ? "Login" : "Sign Up"}
              </button>
            </div>
            <a onClick={() => setIsPassForgot(true)}>Forgot Password?</a>
          </form>
        </LoginCard>
      }
      <MFA isOpen={isMfaChallenge} toggleOpen={() => null} verifyMfa={verifyMfa} />
    </LoginContainer>
  );
}

const SocialButton = styled.a<{
  borderColor?: string;
  iconMarginRight: string;
}>`
  color: #100840;
  height: 48px;
  padding: 0 16px;
  border: 1px solid ${({ borderColor }) => borderColor || `var(--bs-blue)`};
  max-width: 238px;
  border-radius: 4px;
  svg {
    margin-right: ${({ iconMarginRight }) => iconMarginRight};
  }
`;

export const PASSWORD_VALIDATION_PARAMETERS = [
  "min 8 characters",
  "number",
  "special character",
  "uppercase letter",
  "lowercase letter",
  "no whitespace from both ends",
];

export const passwordValidators: {
  [key: string]: (v: string) => boolean;
} = {
  "min 8 characters": isPasswordLongEnough,
  number: containsDigit,
  "special character": containsSpecialCharacter,
  "uppercase letter": containsCapital,
  "lowercase letter": containsNonCapital,
  "no whitespace from both ends": noSpacesOnEnds,
};

// const getPasswordError = (value: string) => {
//   return PASSWORD_VALIDATION_PARAMETERS.reduce((acc: string[], item) => {
//     if (passwordValidators[item] && !passwordValidators[item](value)) {
//       acc.push(item);
//     }
//     return acc;
//   }, [])[0];
// };

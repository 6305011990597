import { useState } from 'react';
import { subMonths, format, startOfMonth, addMonths } from 'date-fns';
import { ReactComponent as ArrowIcon } from 'common/src/icons/arrow-down.svg';

import './DatePickerCalendar.scss';
import Calendar from '../Calendar/Calendar';
interface Props {
  selectedDate?: Date | null;
  onSelect: (d: Date) => void;
  initialDate?: Date;
  name?: string;
}

export default function DatePickerCalendar({
  initialDate,
  selectedDate,
  onSelect,
  name,
}: Props) {
  const [date, setDate] = useState(startOfMonth(initialDate || new Date()));

  const onClickNext = () => {
    setDate((d) => addMonths(d, 1));
  };
  const onClickBack = () => {
    setDate((d) => subMonths(d, 1));
  };

  return (
    <div className='DatePickerCalendar' onClick={(e) => e.stopPropagation()}>
      <div className='row row-cols-auto justify-content-between align-items-center mb-2'>
        <div className='col user-select-none'>{format(date, 'MMMM yyyy')}</div>
        <div className='col'>
          <button
            className='me-2 p-0 bg-transparent border-0 cursor-pointer'
            onClick={onClickBack}
            type='button'
          >
            <ArrowIcon className='DatePickerCalendar__arrow--left' />
          </button>
          <button
            className=' p-0 bg-transparent border-0 cursor-pointer'
            onClick={onClickNext}
            type='button'
          >
            <ArrowIcon className='DatePickerCalendar__arrow--right' />
          </button>
        </div>
      </div>
      <Calendar
        initialDate={date}
        selectedDate={selectedDate}
        onSelect={onSelect}
        name={name}
      />
    </div>
  );
}

import "./Profile.scss";
import { Helmet } from "react-helmet";
import Input from "components/Input/Input";
import { FormikErrors, useFormik } from "formik";
import { Container, Title } from "pages/Dashboard/Dashboard";
import styled from "styled-components";
import Proplan from "components/Proplan";
import Switch from "components/Switch/Switch";
import { Button } from "components-styled/Button";
import { useModal } from "hooks/useModal";
import Modal from "components/Modal";
import { useAppSelector } from "common/src/hooks";
import {
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { useCallback, useEffect, useState } from "react";
import apiClient from "core/apiClient";
import { toast, ToastContainer } from "react-toastify";

interface FormikValues {
  full_name: string;
  email: string;
  old_password: string;
  new_password: string;
  confirm_password: string;
  daily_notifications: boolean;
  weekly_notifications: boolean;
  email_notifications: boolean;
}

export default function Profile() {
  const {
    isModalOpened: isDeleteAccountModalOpened,
    closeModal: closeDeleteAccountModal,
    openModal: openDeleteAccountModal,
  } = useModal();
  const userSlice = useAppSelector((s) => s.user);
  const [token, setToken] = useState<string | null>(null);
  const [debounce, setDebounce] = useState(false);
  // // get a link_token from your API when component mounts
  useEffect(() => {
    const createLinkToken = () => {
      setDebounce(true);
      apiClient
        .getLinkToken()
        .then((res) => {
          console.log(
            "🚀 ~ file: ConnectAccount.tsx ~ line 46 ~ .then ~ res",
            res
          );
          const { link_token } = res;
          setToken(link_token);
        })
        .catch((err) => {
          console.log(
            "🚀 ~ file: ConnectAccount.tsx ~ line 34 ~ apiClient.getLinkToken ~ err",
            err
          );
        });
    };

    if (debounce === false) {
      createLinkToken();
    }
  }, []);

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    console.log(publicToken, metadata);

    apiClient
      .setAccessToken(publicToken)
      .then((res) => {
        console.log(
          "🚀 ~ file: ConnectAccount.tsx ~ line 46 ~ .then ~ res",
          res
        );
        toast.success(
          "Request completed",
          {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: ConnectAccount.tsx ~ line 34 ~ apiClient.getLinkToken ~ err",
          err
        );
        console.info('err.response.data.error_message', err.response.data.error_message)
        toast.error(err.response.data.error_message, {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  }, []);

  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    console.log(eventName, metadata);
  }, []);

  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    console.log(error, metadata);
  }, []);

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onEvent,
    onExit,
  };

  const {
    open,
    ready,
    // error,
    // exit
  } = usePlaidLink(config);

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: userSlice.user.email,
      old_password: "",
      new_password: "",
      confirm_password: "",
      daily_notifications: false,
      weekly_notifications: false,
      email_notifications: true,
    },
    validate: (values) => {
      const errors: FormikErrors<FormikValues> = {};
      return errors;
    },
    onSubmit: (values) => { },
  });

  const deleteAccount = () => {
    apiClient.deleteAccount().then((res) => {
      toast.success(res.message,
        {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      localStorage.clear();
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    });
  };

  return (
    <Container
      className="w-100 mx-auto"
      style={{
        paddingBottom: 84,
      }}
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <title>Diane Money | Profile </title>
      </Helmet>
      <div className="mb-4">
        <Title className="fw-800 text-gray text-uppercase">Profile</Title>
      </div>
      <Card>
        <form>
          <CategoryTitle className="mb-4 fw-800">
            Personal Account
          </CategoryTitle>
          <Input
            name="full_name"
            value={formik.values.full_name}
            onChange={formik.handleChange}
            placeholder="Full Name"
            className="mb-3 w-100 Input--Profile"
          />
          <Input
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="Email"
            className="mb-4 w-100 Input--Profile mt-n1"
          />
          <CategoryTitle className="mb-3 fw-800">Change Password</CategoryTitle>
          <Input
            name="old_password"
            value={formik.values.old_password}
            onChange={formik.handleChange}
            placeholder="Old Password"
            className="mb-3 w-100 Input--Profile"
            type="password"
          />
          <Input
            name="new_password"
            value={formik.values.new_password}
            onChange={formik.handleChange}
            placeholder="New Password"
            className="mb-3 w-100 Input--Profile mt-n1"
            type="password"
          />
          <Input
            name="confirm_password"
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            placeholder="Confirm Password"
            className="mb-4 w-100 Input--Profile mt-n1"
            type="password"
          />
          {(formik.values.old_password ||
            formik.values.new_password ||
            formik.values.confirm_password) && (
              <div className="mb-3">
                <Button type="button">Save Password</Button>
              </div>
            )}
          <GrayButton
            className="text-gray p-0 border-0 bg-transparent cursor-pointer mb-5"
            type="button"
            onClick={openDeleteAccountModal}
          >
            Delete Account
          </GrayButton>
          <Separator className="mb-5" />
          <CategoryTitle className="mb-4 fw-800">Your Plan</CategoryTitle>
          <div>
            <PlanButton>
              Current Plan: {userSlice.user.isPremium ? 'Premium' : 'Basic Free'}
            </PlanButton>
            <div className="py-5">
              <Proplan buttonAlign="left" />
            </div>
          </div>
          <Separator className="mb-5" />
          <CategoryTitle className="mb-4 fw-800">Notifications</CategoryTitle>
          <Switch
            checked={formik.values.daily_notifications}
            onChange={formik.handleChange}
            label="Daily Push Notifications"
            name="daily_notifications"
            className="mb-4"
          />
          <Switch
            checked={formik.values.weekly_notifications}
            onChange={formik.handleChange}
            label="Weekly Push Notifications"
            name="weekly_notifications"
            className="mb-4"
          />
          <Switch
            checked={formik.values.email_notifications}
            onChange={formik.handleChange}
            label="Email Notifications"
            name="email_notifications"
            className="mb-5"
          />
          <Separator className="mb-5" />
          <CategoryTitle className="mb-4 fw-800">
            Your Bank Account
          </CategoryTitle>
          <Button type="button" onClick={() => open()}>
            View Your Account
          </Button>
          <Separator className="my-5" />
          <div className="d-flex flex-column">
            {/* <div className="mb-3 text-center">
              <GrayLink href="#">Security</GrayLink>
            </div> */}
            <div className="mb-3 text-center">
              {/* eslint-disable-next-line */}
              <GrayLink href="https://app.termly.io/document/terms-of-use-for-mobile-app/259d1615-e6d5-46fb-abb3-7281f602ffd7">Terms of Service</GrayLink>
            </div>
            <div className="mb-3 text-center">
              {/* eslint-disable-next-line */}
              <GrayLink href="https://app.termly.io/document/privacy-policy/b2dfe2fe-9438-42cc-8b58-e8e10e007509">Privacy Policy</GrayLink>
            </div>
          </div>
        </form>
      </Card>
      <Modal
        isOpened={isDeleteAccountModalOpened}
        closeModal={closeDeleteAccountModal}
      >
        <div className="d-flex flex-column align-items-center pt-3">
          <CategoryTitle className="mb-4 fw-800 text-center ">
            Are you sure you want to delete your account?<br />
            Please note that, all your data and information associated with the account will be deleted permanently. This action cannot be undone
            However, You can discard the deletion request by simply logging into your account before the 15 days after Deletion Request. Once you log in, the deletion request will be cancelled, and your account will be active again.

          </CategoryTitle>
          <Button onClick={() => { deleteAccount(); closeDeleteAccountModal() }}>
            Yes, delete account.</Button>
        </div>
      </Modal>
    </Container>
  );
}

const Card = styled.div`
  background: #ffffff;
  border: 1px solid #ebeaeb;
  border-radius: 10px;
  padding: 40px 60px;
`;

const CategoryTitle = styled.div`
  font-size: 24px;
  line-height: 37px;
`;

const Separator = styled.div`
  background-color: #d1cfdc;
  height: 1px;
`;

const Badge = styled.div`
  padding: 18px 10px;
  border: 2px solid #ebeaeb;
  border-radius: 10px;
  font-size: 16px;
  line-height: 17px;
`;

const GrayLink = styled.a`
  color: #8d89a3;
  transition: color 0.25s;
  &:hover {
    color: var(--bs-dark);
  }
`;
const GrayButton = styled.button`
  color: #8d89a3;
  transition: color 0.25s;
  &:hover {
    color: var(--bs-dark);
  }
`;
const PlanButton = styled.span`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  height: 57px;
  border: 2px solid #EBEAEB;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
`;

import LoginContainer from "../containers/LoginContainer";
import { ReactComponent as Icon } from "common/src/icons/welcome.svg";
import { ReactComponent as Arrow } from "common/src/icons/arrow-right.svg";
import { WelcomeCard } from "../components-styled/Card";
import { Button } from "components-styled/Button";

interface Props {
  onClick: () => void;
}

export default function Welcome({ onClick }: Props) {
  return (
    <LoginContainer>
      <WelcomeCard className="mx-auto d-flex flex-column align-items-center">
        <Icon />
        <div className="mt-2 mb-3 text--24 fw-800 text-uppercase">Welcome</div>
        <div className="text-gray">
          <div
            className="mb-4 text-center"
            style={{
              maxWidth: 774,
            }}
          >
            Are you ready to have the guesses and stresses removed from your
            financial life? Great! Diane is ready to be your guide towards a
            bigger and better financial future.
          </div>
          <div className="mb-3 pb-3 text-center">
            Let’s start by setting your first goal.
          </div>
        </div>
        <Button
          className="d-flex align-items-center justify-content-center"
          onClick={onClick}
        >
          <span className="me-2">Continue</span> <Arrow />
        </Button>
      </WelcomeCard>
    </LoginContainer>
  );
}

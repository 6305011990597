import {
  format,
  startOfMonth,
  startOfWeek,
  getMonth,
  startOfDay,
  addDays,
} from 'date-fns';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import './Calendar.scss';

interface Props {
  selectedDate?: Date | null;
  onSelect: (d: Date) => void;
  initialDate: Date;
  className?: string;
  name?: string;
}

export default function Calendar({
  initialDate,
  selectedDate,
  onSelect,
  className = '',
  name,
}: Props) {
  const renderDaysOfWeek = () => {
    const weekStartDate = startOfWeek(initialDate);
    const weekDays = [];
    for (let day = 1; day < 8; day++) {
      weekDays.push(
        <div
          key={day}
          className='d-flex align-items-center justify-content-center Calendar__cell Calendar__dayOfWeek'
        >
          {format(addDays(weekStartDate, day), 'EEEEEE')}
        </div>
      );
    }
    return <div className='d-grid Calendar__row '>{weekDays}</div>;
  };

  const handleSelect = (d: Date) => () => {
    onSelect(d);
  };

  const generateDates = (d: Date) => {
    const week = [];
    for (let day = 0; day < 7; day++) {
      const isInactive = getMonth(d)! !== getMonth(initialDate);
      week.push(
        <div
          className={classNames(
            'd-flex align-items-center justify-content-center Calendar__cell Calendar__cell--day',
            {
              'Calendar__cell--inactive': isInactive,
              'Calendar__cell--selected':
                selectedDate &&
                startOfDay(selectedDate).getTime() === startOfDay(d).getTime(),
            }
          )}
          data-testid={
            name
              ? `${name}-cell-${isInactive ? 'incative' : 'active'}-${format(
                  d,
                  'd'
                )}`
              : undefined
          }
          onClick={!isInactive ? handleSelect(d) : undefined}
          key={d.getTime()}
        >
          {format(d, 'd')}
        </div>
      );
      d = addDays(d, 1);
    }
    return (
      <div className='d-grid Calendar__row Calendar__week' key={uuidv4()}>
        {week}
      </div>
    );
  };

  const renderDates = () => {
    const startOfTheSelectedMonth = startOfMonth(initialDate);
    let startDate = startOfWeek(startOfTheSelectedMonth, { weekStartsOn: 1 });

    const weeks = [];

    while (weeks.length < 6) {
      weeks.push(generateDates(startOfDay(startDate)));
      startDate = addDays(startDate, 7);
    }

    return <div className='Calendar__grid'>{weeks}</div>;
  };

  return (
    <div className={`Calendar ${className}`}>
      {renderDaysOfWeek()}
      {renderDates()}
    </div>
  );
}

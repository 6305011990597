import styled from "styled-components";

/** Presentational */
import Modal from "components/Modal";

/** app hooks */
import { useAppSelector } from "common/src/hooks";

import { Button } from "components-styled/Button";
import Input from "../components/Input/Input";
import { useState } from "react";

type Props = {
    isOpen: boolean;
    toggleOpen: () => void;
    verifyMfa: (code: string) => void;
};

export default function MFA({ isOpen, toggleOpen, verifyMfa }: Props) {
    const user = useAppSelector((s) => s.user.user);
    const [otp, setOtp] = useState('');

    const onOtpChange = (val: string) => {
        setOtp(val.replace(/\D/g, '').substring(0, 6));
    }

    return (
        <Modal isOpened={isOpen} closeModal={() => toggleOpen()}>
            <div className="d-flex flex-column align-items-center pt-4">
                <CategoryTitle className="mb-3 fw-700 text-center">
                    PLEASE ENTER YOUR MULTI-FACTOR AUTHENTICATION CODE
                </CategoryTitle>
                <Subtitle className="mb-4">The code will be sent to your email address.</Subtitle>
                <div className="my-1" style={{ minWidth: 380 }}>
                    <Input
                        name="text"
                        value={otp}
                        onChange={(e: any) => onOtpChange(e.target?.value)}
                        placeholder="Code"
                        className="mb-2 w-100"
                    />
                </div>
                <Button type="submit" className="mt-4" disabled={otp.length !== 6} onClick={() => verifyMfa(otp)}>
                    Sounds Good!
                </Button>
            </div>
        </Modal>
    );
}

const CategoryTitle = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
`;

const Subtitle = styled.div`
    font-size: 16px;
    line-height: 19px;
    color: #8D89A3;
`;

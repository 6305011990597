import styled from "styled-components";
import { ReactComponent as Star } from "common/src/icons/star.svg";
import { ReactComponent as GrayStar } from "common/src/icons/star-gray.svg";
import { useRef } from "react";
import "./Tile.scss";
import classNames from "classnames";
import { formatNumber } from "helpers";

interface TileDescription {
  label: string;
  value: string;
  color?: "green" | "purple" | "red";
  badge?: string;
  badgeTop?: boolean;
}

interface Props {
  id: number;
  isDebt?: boolean;
  className?: string;
  title: string;
  image?: string;
  star?: boolean;
  onExpand: () => void;
  isExpanded: boolean;
  content: TileDescription[];
  btns: JSX.Element;
  small?: boolean;
  amount: number;
  total: number;
  onSetGoalAsPrimary?: (id: number) => void;
}

export default function Tile({
  id,
  isDebt,
  className = "",
  title = "",
  image,
  star = true,
  isExpanded,
  onExpand,
  content,
  btns,
  small,
  amount,
  total,
  onSetGoalAsPrimary,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const renderContent = () => (
    <div ref={ref}>
      <div ref={ref} className="Tile__description">
        <Row className="row row-cols-auto px-5 justify-content-center">
          {content.map((d) => (
            <Col
              key={d.label}
              className="col d-flex flex-column align-items-center"
            >
              <div className="text-gray mb-2 pb-1 Tile__label text-center">
                {d.label}
              </div>
              <div
                className={classNames(
                  "text-center d-flex justify-content-center",
                  {
                    "align-items-center": !d.badgeTop,
                    "align-items-start": d.badgeTop,
                  }
                )}
              >
                <div
                  className={classNames("Tile__value fw-800", {
                    "Tile__value--green": d.color === "green",
                    "Tile__value--purple": d.color === "purple",
                    "Tile__value--red": d.color === "red",
                  })}
                >
                  {d.value}
                </div>
                {d.badge && (
                  <div
                    className={classNames("Tile__badge ms-1", {
                      "text-gray": !d.badgeTop,
                      "Tile__badge--top": d.badgeTop,
                    })}
                  >
                    {d.badge}
                  </div>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div
        className="Tile__actions"
        style={{
          cursor: "default",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {btns}
      </div>
    </div>
  );

  return (
    <div
      onClick={onExpand}
      className={classNames("Tile overflow-hidden cursor-pointer", className, {
        "Tile--sm": small && !isExpanded,
      })}
    >
      <div className="d-flex">
        {image && (
          <div className="d-flex Tile__imageContainer">
            <img src={image} alt="goal" className="w-100 h-100" />
          </div>
        )}
        <div className="flex-grow-1 Tile__content">
          {isDebt === undefined && (
            <div
              className="Tile__icon"
              onClick={() => onSetGoalAsPrimary && onSetGoalAsPrimary(id)}
            >
              {small && !star ? <GrayStar /> : <Star />}
            </div>
          )}
          <div className="mb-3 fw-800 Tile__title">{title}</div>
          <div className="mb-3 Tile__text">
            <span className="text-gray">
              {formatNumber(amount, {
                currency: "usd",
              })}
              /
            </span>
            <span>
              {formatNumber(total, {
                currency: "usd",
              })}
            </span>
          </div>
          <Progress
            value={Math.round((amount / total) * 100)}
            small={small && !isExpanded}
            className="mb-4"
          />
        </div>
      </div>
      <div
        className="Tile__collapseContent"
        style={{
          maxHeight: isExpanded ? ref.current?.offsetHeight || 0 : 0,
          opacity: isExpanded ? 1 : 0,
        }}
      >
        {renderContent()}
      </div>
    </div>
  );
}

export const Progress = styled.div<{ value: number; small?: boolean }>`
  height: 25px;
  border-radius: 25px;
  background-color: #ebeaeb;
  position: relative;

  &:after {
    content: "";
    border-radius: 25px;
    width: ${({ value }) => value}%;
    height: 100%;
    background-color: #ff997d;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ small }) =>
    small
      ? `height: 14px;
  border-radius: 14px;
  max-width: 350px;

  &:after {
    border-radius: 14px;
  }`
      : ""}
`;

const Col = styled.div`
  padding: 0 50px;
  &:not(:last-child) {
    border-right: 1px solid #ebeaeb;
  }
`;
const Row = styled.div`
  margin: 0 -50px;
`;

import { createSlice } from "@reduxjs/toolkit";

interface MetricsState {
  metrics: {
    month_until_primary_goal_is_met: string;
    monthly_inflow: number;
    monthly_outflow: number;
    months_until_debt_free: number;
    total_debt: number;
    total_minimum_debt_payment: number;
    total_mortgage: number;
  };
}

const initialState: MetricsState = {
  metrics: {
    month_until_primary_goal_is_met: "",
    monthly_inflow: 0,
    monthly_outflow: 0,
    months_until_debt_free: 0,
    total_debt: 0,
    total_minimum_debt_payment: 0,
    total_mortgage: 0,
  },
};

export const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {
    updateMetrics: (state, action) => {
      state.metrics = {
        ...state.metrics,
        month_until_primary_goal_is_met:
          action.payload.month_until_primary_goal_is_met,
        monthly_inflow: action.payload.monthly_inflow,
        monthly_outflow: action.payload.monthly_outflow,
        months_until_debt_free: action.payload.months_until_debt_free,
        total_debt: action.payload.total_debt,
        total_minimum_debt_payment: action.payload.total_minimum_debt_payment,
        total_mortgage: action.payload.total_mortgage,
      };
    },
  },
});

export const { updateMetrics } = metricsSlice.actions;

export default metricsSlice.reducer;

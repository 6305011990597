import { FormikErrors, useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";

/** Presentational */
import Input from "components/Input/Input";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import styled from "styled-components";
import { OnboardingCard } from "components-styled/Card";
import Checkbox from "components/Checkbox/Checkbox";
import Select from "components/Select/Select";
import DatePicker from "components/DatePicker/DatePicker";
import MoneyIcon from "common/src/icons/goals/money.svg";
import HouseIcon from "common/src/icons/goals/house.svg";
import { ReactComponent as PlusIcon } from "common/src/icons/goals/plus.svg";
import { ReactComponent as CrossIcon } from "common/src/icons/cross.svg";
import { Button } from "components-styled/Button";
import FileUploader from "components/FileUploader/FileUploader";

/** Containers */
import LoginContainer from "containers/LoginContainer";
import { useAppDispatch, useAppSelector } from "common/src/hooks";
import {
  capitalize,
  dataURLtoBlob,
  getDataUrl,
  isDataURI,
  normalizeDateBE,
  srcToDataURI,
} from "helpers";
import apiClient from "core/apiClient";
import { GOAL_TYPE_OPTIONS } from "utils/constants";
import { useState } from "react";
import { S3_URL } from "common/src/auth";
import { updateGoals } from "common/src/store/goals/goalsSlice";

import mixpanel from "utils/mixpanel"

export interface EditGoalFormValues {
  name: string;
  is_primary: boolean;
  amount: number;
  saving: number;
  type: string;
  target_date: Date | null;
  image_url: string;
}

interface Props {
  onSubmit?: (v: EditGoalFormValues) => void;
}

const ICONS = [MoneyIcon, HouseIcon];

export default function CreateGoal({ onSubmit }: Props) {
  let navigate = useNavigate();

  const dispatch = useAppDispatch();
  const isOnboarding = useAppSelector((s) => s.user.onboarding);
  const goal = useAppSelector((s) => s.goals.editGoal);
  const [isLoading, setIsLoading] = useState(false);
  const [fileType, setFileType] = useState("svg");
  const [fileContentType, setFileContentType] = useState("image/svg+xml");
  const [isImageChanged, setIsImageChanged] = useState(false);
  const { id, name, amount, saving, is_primary, target_date, image_url, image_presigned_url } = goal;

  const onEditGoal = async (v: EditGoalFormValues) => {
    setIsLoading(true);

    if (fileContentType === "image/svg+xml") {
      const imageSrcS3 = v.image_url.includes("house")
        ? `${S3_URL}/house.svg`
        : `${S3_URL}/money.svg`;
      apiClient
        .updateGoal({
          ...v,
          id,
          name: v.name,
          amount: v.amount,
          saving: v.saving,
          image_url: imageSrcS3,
          target_date: normalizeDateBE(v.target_date as Date),
        })
        .then((res) => {
          mixpanel.track("Goal edited")
          dispatch(updateGoals(res))
          setIsLoading(false);
          onClickClose();
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("🚀 ~ file: EditGoal.tsx ~ line 103 ~ .then ~ err", err);
        });
    } else {
      const imageName = `${uuidv4()}.${fileType}`;

      apiClient.uploadGoalImage(v.image_url, imageName)
        .then((res) => {
          apiClient
            .updateGoal({
              ...v,
              id,
              name: v.name,
              amount: v.amount,
              saving: v.saving,
              image_url: `${S3_URL}/${imageName}`,
              target_date: normalizeDateBE(v.target_date as Date),
            })
            .then((res) => {
              dispatch(updateGoals(res))
              console.log(
                "🚀 ~ file: EditGoal.tsx ~ line 108 ~ .then ~ res",
                res
              );
              setIsLoading(false);
              onClickClose();
            })
            .catch(console.error);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(2, "🚀 ~ file: EditGoal.tsx ~ line 103 ~ .then ~ err", err);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      name,
      is_primary,
      amount,
      saving,
      type: "option_1",
      target_date: new Date(target_date),
      image_url: image_url,
    },
    validate: (values) => {
      const errors: FormikErrors<EditGoalFormValues> = {};
      if (!values.name) {
        errors.name = "Required";
      }
      if (!values.type) {
        errors.type = "Required";
      }
      if (!values.amount) {
        errors.amount = "Required";
      }
      if (!values.saving) {
        errors.saving = "Required";
      }
      if (!values.target_date) {
        errors.target_date = "Required";
      }

      return errors;
    },
    onSubmit: (values) => {
      onEditGoal(values);
    },
  });

  const onAmountChange: (v: string | undefined) => void = (v) => {
    formik.setFieldValue("amount", v, true);
    setTimeout(() => {
      formik.setFieldTouched("amount", true, true);
    }, 10);
  };

  const onSavingChange: (v: string | undefined) => void = (v) => {
    formik.setFieldValue("saving", v, true);
    setTimeout(() => {
      formik.setFieldTouched("saving", true, true);
    }, 10);
  };

  const onClickClose = () => {
    navigate("/");
  };

  return (
    <LoginContainer footer={false}>
      <OnboardingCard
        className={classNames("mx-auto", {
          "pt-4": !isOnboarding,
        })}
      >
        {!isOnboarding && (
          <div className="text-end mb-2">
            <CloseButton
              className="p-0 border-0 p-0 bg-transparent cursor-pointer"
              onClick={onClickClose}
            >
              <CrossIcon />
            </CloseButton>
          </div>
        )}
        <div className="px-5 mx-1">
          <div className="text-uppercase mb-2 text--24 fw-800 text-center">
            Edit Goal {name}
          </div>
          <form onSubmit={formik.handleSubmit}>
            <Row className="row gx-5">
              <LeftColumn className="col flex-grow-1">
                <Input
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  placeholder="Name"
                  className="pb-4"
                  error={formik.touched.name && formik.errors.name}
                  onBlur={formik.handleBlur}
                />
                <Checkbox
                  checked={formik.values.is_primary}
                  onChange={(e) => {
                    formik.setFieldValue("is_primary", e.target.checked);
                  }}
                  label="Is this your most important goal? If so, check this box."
                  className="mb-4"
                />
                <CurrencyInput
                  name="amount"
                  value={formik.values.amount}
                  onValueChange={onAmountChange}
                  placeholder="Target Amount"
                  className="mb-4"
                  error={formik.touched.amount && formik.errors.amount}
                  onBlur={formik.handleBlur}
                />
                <CurrencyInput
                  name="saving"
                  value={formik.values.saving}
                  onValueChange={onSavingChange}
                  placeholder="How much have you saved so far?"
                  className="mb-4"
                  error={formik.touched.saving && formik.errors.saving}
                  onBlur={formik.handleBlur}
                />
                <Select
                  value={formik.values.type}
                  options={GOAL_TYPE_OPTIONS}
                  onSelect={(v: string) => () => {
                    formik.setFieldValue("type", v, true);
                    setTimeout(() => {
                      formik.setFieldTouched("type", true, true);
                    }, 0);
                  }}
                  placeholder="Goal Type"
                  error={formik.touched.type && formik.errors.type}
                />
                <DatePicker
                  className="mt-4"
                  selectedDate={formik.values.target_date}
                  onSelect={(d: Date | null) => {
                    formik.setFieldValue("target_date", d, true);
                  }}
                />
              </LeftColumn>
              <RightColumn className="col">
                <div className="text-gray mb-2">Goal Image</div>
                <ImageTile className="d-flex mb-2 overflow-hidden">
                  <img
                    src={isImageChanged ? formik.values.image_url : image_presigned_url}
                    alt="active"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                </ImageTile>
                <div className="row gx-2 row-cols-auto">
                  {ICONS.map((v) => {
                    return (
                      <div className="col" key={v}>
                        <ImageButton
                          type="button"
                          active={formik.values.image_url === v}
                          onClick={() => {
                            formik.setFieldValue("image_url", v);
                            setFileType("svg");
                          }}
                        >
                          <img src={v} alt="choice" />
                        </ImageButton>
                      </div>
                    );
                  })}
                  <div className="col">
                    <FileUploader
                      onSelect={async (files: File[] | null) => {
                        if (!files?.[0] || files[0].type.split('/')[0] != 'image') return;
                        setIsImageChanged(true);
                        setFileType(files[0].name.split(".").reverse()[0]);
                        setFileContentType(files[0].type);

                        const imageSrc = await getDataUrl(files[0]);
                        if (typeof imageSrc === "string") {
                          formik.setFieldValue("image_url", imageSrc);
                        }
                      }}
                    >
                      <PlusButton>
                        <PlusIcon />
                      </PlusButton>
                    </FileUploader>
                  </div>
                </div>
              </RightColumn>
            </Row>
            <div className="text-center mt-4 pt-2">
              <Button type="submit" disabled={isLoading}>
                {isLoading ? `Loading....` : `Edit Goal`}
              </Button>
            </div>
          </form>
        </div>
      </OnboardingCard>
    </LoginContainer>
  );
}

export const CloseButton = styled.button`
  svg {
    transition: transform 250ms;
  }
  &:hover {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const Row = styled.div`
  margin: 0 -25px;
`;

const Column = styled.div`
  padding: 0 25px;
`;
const LeftColumn = styled(Column)`
  padding-top: 29px;
`;
const RightColumn = styled(Column)`
  max-width: 304px;
`;

const ImageTile = styled.div<{ gray?: boolean }>`
  border: 1px solid ${({ gray }) => (gray ? "#d1cfdc" : "#ebeaeb")};
  background: ${({ gray }) => (gray ? "#d1cfdc" : "#ebeaeb")};
  border-radius: 10px;
  width: 254px;
  height: 254px;
  svg {
    margin: auto;
    width: ${({ gray }) => (gray ? "170px" : "100%")};
    height: ${({ gray }) => (gray ? "170px" : "100%")};
  }
`;
const ImageButton = styled.button<{
  active?: boolean;
}>`
  outline: none;
  padding: 0;
  display: flex;
  border: 2px solid ${({ color }) => color || "#ebeaeb"};
  background: ${({ color }) => color || "#ebeaeb"};
  border-radius: 10px;
  width: 40px;
  height: 40px;
  svg {
    margin: auto;
    width: 100%;
    height: 100%;
  }
  ${({ active }) =>
    active ? `border: 2px solid #5959E3; background: #fff;` : ""}
  cursor: pointer;
`;
const PlusButton = styled.div<{
  active?: boolean;
}>`
  outline: none;
  padding: 0;
  display: flex;
  border: 2px solid ${({ color }) => color || "#ebeaeb"};
  background: ${({ color }) => color || "#ebeaeb"};
  border-radius: 10px;
  width: 40px;
  height: 40px;
  svg {
    margin: auto;
    width: 100%;
    height: 100%;
  }
  ${({ active }) =>
    active ? `border: 2px solid #5959E3; background: #fff;` : ""}
  cursor: pointer;
  border: 2px solid #d1cfdc;
  background: #d1cfdc;
  svg {
    width: 16px;
    height: 16px;
  }

  ${({ active }) => (active ? `border: 2px solid #5959E3; ` : "")}
`;

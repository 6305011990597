import {
  ChangeEventHandler,
  DragEventHandler,
  useRef,
  PropsWithChildren,
} from 'react';

interface Props {
  accept?: string[];
  name?: string;
  onSelect: (files: File[] | null) => void;
  className?: string;
  sizeLimit?: number;
  label?: string;
  multiple?: boolean;
}

export default function FileUploader({
  accept = ['png', 'jpg', 'jpeg', 'heic'],
  name = 'file',
  onSelect,
  className = '',
  children,
  multiple = false,
}: PropsWithChildren<Props>) {
  const ref = useRef<HTMLInputElement>(null);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onSelect(e.target.files ? Array.from(e.target.files) : null);
  };

  const preventDefaultDragEvents: DragEventHandler<HTMLLabelElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop: DragEventHandler<HTMLLabelElement> = (e) => {
    preventDefaultDragEvents(e);
    onSelect(e.dataTransfer.files ? Array.from(e.dataTransfer.files) : null);
  };

  return (
    <label
      className={`FileUploader ${className}`}
      onDrop={onDrop}
      onDragEnter={preventDefaultDragEvents}
      onDragLeave={preventDefaultDragEvents}
      onDragOver={preventDefaultDragEvents}
    >
      {children}
      <input
        name={name}
        type='file'
        onChange={onChange}
        className='d-none'
        accept={accept.map((str) => `.${str}`).join(', ')}
        ref={ref}
        multiple={multiple}
      />
    </label>
  );
}

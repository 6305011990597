export const DEBT_TYPE_OPTIONS = [
  { value: "auto", label: "Auto" },
  { value: "mortgage", label: "Mortgage" },
  { value: "student", label: "Student" },
  { value: "credit card/ consumer", label: "Credit Card / Consumer" },
  { value: "other", label: "Other" },
];

export const GOAL_TYPE_OPTIONS = [
  {
    value: "Vacation / Experience",
    label: "Vacation / Experience",
  },
  { value: "Invest More", label: "Invest More" },
  { value: "Personal Item", label: "Personal Item" },
  { value: "Debt Reduction", label: "Debt Reduction" },
];

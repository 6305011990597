import { InputHTMLAttributes } from 'react';

export type CurrencyInputOnChangeValues = {
  float: number | null;
  formatted: string;
  value: string;
};

export type IntlConfig = {
  locale: string;
  currency?: string;
};

export type CurrencyInputProps = {
  id?: string;
  className?: string;
  decimalsLimit?: number;
  decimalScale?: number;
  onValueChange: (
    value: string | undefined,
    name?: string,
    values?: CurrencyInputOnChangeValues
  ) => void;
  placeholder?: string;
  decimalSeparator?: string;
  groupSeparator?: string;
  intlConfig?: IntlConfig;
  error?: boolean | string | undefined;
  label?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export type CleanValueOptions = Pick<
  CurrencyInputProps,
  'decimalSeparator' | 'groupSeparator' | 'decimalsLimit' | 'prefix'
> & { value: string };

type LocaleConfig = {
  currencySymbol: string;
  groupSeparator: string;
  decimalSeparator: string;
  prefix: string;
  suffix: string;
};

const defaultConfig: LocaleConfig = {
  currencySymbol: '',
  groupSeparator: '',
  decimalSeparator: '',
  prefix: '',
  suffix: '',
};

type Options = {
  decimalSeparator?: string;
  groupSeparator?: string;
};

type RepositionCursorProps = {
  selectionStart?: number | null;
  value: string;
  lastKeyStroke: string | null;
  stateValue?: string;
  groupSeparator?: string;
};

export const repositionCursor = ({
  selectionStart,
  value,
  lastKeyStroke,
  stateValue,
  groupSeparator,
}: RepositionCursorProps): {
  modifiedValue: string;
  cursorPosition: number | null | undefined;
} => {
  let cursorPosition = selectionStart;
  let modifiedValue = value;
  if (stateValue && cursorPosition) {
    const splitValue = value.split('');
    // if cursor is to right of groupSeparator and backspace pressed, delete the character to the left of the separator and reposition the cursor
    if (
      lastKeyStroke === 'Backspace' &&
      stateValue[cursorPosition] === groupSeparator
    ) {
      splitValue.splice(cursorPosition - 1, 1);
      cursorPosition -= 1;
    }
    // if cursor is to left of groupSeparator and delete pressed, delete the character to the right of the separator and reposition the cursor
    if (
      lastKeyStroke === 'Delete' &&
      stateValue[cursorPosition] === groupSeparator
    ) {
      splitValue.splice(cursorPosition, 1);
      cursorPosition += 1;
    }
    modifiedValue = splitValue.join('');
    return { modifiedValue, cursorPosition };
  }

  return { modifiedValue, cursorPosition: selectionStart };
};

export const getSuffix = (
  value: string,
  { groupSeparator = ',', decimalSeparator = '.' }: Options
): string | undefined => {
  const suffixReg = new RegExp(
    `\\d([^${escapeRegExp(groupSeparator)}${escapeRegExp(
      decimalSeparator
    )}0-9]+)`
  );
  const suffixMatch = value.match(suffixReg);
  return suffixMatch ? suffixMatch[1] : undefined;
};

export const getLocaleConfig = (intlConfig?: IntlConfig): LocaleConfig => {
  const { locale, currency } = intlConfig || { locale: 'en', style: 'decimal' };
  const numberFormatter = locale
    ? new Intl.NumberFormat(
        locale,
        currency ? { currency, style: 'currency' } : undefined
      )
    : new Intl.NumberFormat();

  return numberFormatter
    .formatToParts(1000.1)
    .reduce((prev, curr, i): LocaleConfig => {
      if (curr.type === 'currency') {
        if (i === 0) {
          return { ...prev, currencySymbol: curr.value, prefix: curr.value };
        } else {
          return { ...prev, currencySymbol: curr.value, suffix: curr.value };
        }
      }
      if (curr.type === 'group') {
        return { ...prev, groupSeparator: curr.value };
      }
      if (curr.type === 'decimal') {
        return { ...prev, decimalSeparator: curr.value };
      }

      return prev;
    }, defaultConfig);
};

export const cleanValue = ({
  value,
  groupSeparator = ',',
  decimalSeparator = '.',
  decimalsLimit = 2,
  prefix = '',
}: CleanValueOptions): string => {
  if (value === '-') {
    return value;
  }

  const reg = new RegExp(`((^|\\D)-\\d)|(-${escapeRegExp(prefix)})`);
  const isNegative = reg.test(value);

  // Is there a digit before the prefix? eg. 1$
  const [prefixWithValue, preValue] =
    RegExp(`(\\d+)-?${escapeRegExp(prefix)}`).exec(value) || [];
  const withoutPrefix = prefix
    ? prefixWithValue
      ? value.replace(prefixWithValue, '').concat(preValue)
      : value.replace(prefix, '')
    : value;
  const withoutSeparators = removeSeparators(withoutPrefix, groupSeparator);
  const withoutInvalidChars = removeInvalidChars(withoutSeparators, [
    groupSeparator,
    decimalSeparator,
  ]);

  const raw = withoutInvalidChars;

  const includeNegative = isNegative ? '-' : '';

  if (decimalSeparator && raw.includes(decimalSeparator)) {
    const [int, decimals] = withoutInvalidChars.split(decimalSeparator);
    const trimmedDecimals =
      decimalsLimit && decimals ? decimals.slice(0, decimalsLimit) : decimals;
    const includeDecimals = `${decimalSeparator}${trimmedDecimals}`;

    return `${includeNegative}${int}${includeDecimals}`;
  }

  return `${includeNegative}${raw}`;
};

export const addSeparators = (value: string, separator = ','): string => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const escapeRegExp = (stringToGoIntoTheRegex: string): string => {
  // eslint-disable-next-line
  return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export type FormatValueOptions = {
  value: string | undefined;
  decimalSeparator?: string;
  groupSeparator?: string;
  intlConfig?: IntlConfig;
  decimalScale?: number;
  prefix?: string;
  suffix?: string;
};

export const formatValue = (options: FormatValueOptions): string => {
  const {
    value: _value,
    decimalSeparator,
    intlConfig,
    decimalScale,
    prefix = '',
    suffix = '',
  } = options;

  if (_value === '' || _value === undefined) {
    return '';
  }

  if (_value === '-') {
    return '-';
  }

  const isNegative = new RegExp(
    `^\\d?-${prefix ? `${escapeRegExp(prefix)}?` : ''}\\d`
  ).test(_value);

  const value =
    decimalSeparator !== '.'
      ? replaceDecimalSeparator(_value, decimalSeparator, isNegative)
      : _value;

  const numberFormatter = intlConfig
    ? new Intl.NumberFormat(
        intlConfig.locale,
        intlConfig.currency
          ? {
              style: 'currency',
              currency: intlConfig.currency,
              minimumFractionDigits: decimalScale || 0,
              maximumFractionDigits: 20,
            }
          : undefined
      )
    : new Intl.NumberFormat(undefined, {
        minimumFractionDigits: decimalScale || 0,
        maximumFractionDigits: 20,
      });

  const parts = numberFormatter.formatToParts(Number(value));

  let formatted = replaceParts(parts, options);

  // Does intl formatting add a suffix?
  const intlSuffix = getSuffix(formatted, { ...options });

  // Include decimal separator if user input ends with decimal separator
  const includeDecimalSeparator =
    _value.slice(-1) === decimalSeparator ? decimalSeparator : '';

  const [, decimals] = value.match(RegExp('\\d+\\.(\\d+)')) || [];

  // Keep original decimal padding if no decimalScale
  if (decimalScale === undefined && decimals && decimalSeparator) {
    if (formatted.includes(decimalSeparator)) {
      formatted = formatted.replace(
        RegExp(`(\\d+)(${escapeRegExp(decimalSeparator)})(\\d+)`, 'g'),
        `$1$2${decimals}`
      );
    } else {
      if (intlSuffix && !suffix) {
        formatted = formatted.replace(
          intlSuffix,
          `${decimalSeparator}${decimals}${intlSuffix}`
        );
      } else {
        formatted = `${formatted}${decimalSeparator}${decimals}`;
      }
    }
  }

  if (suffix && includeDecimalSeparator) {
    return `${formatted}${includeDecimalSeparator}${suffix}`;
  }

  if (intlSuffix && includeDecimalSeparator) {
    return formatted.replace(
      intlSuffix,
      `${includeDecimalSeparator}${intlSuffix}`
    );
  }

  if (intlSuffix && suffix) {
    return formatted.replace(intlSuffix, `${includeDecimalSeparator}${suffix}`);
  }

  return [formatted, includeDecimalSeparator, suffix].join('');
};

const replaceDecimalSeparator = (
  value: string,
  decimalSeparator: FormatValueOptions['decimalSeparator'],
  isNegative: boolean
): string => {
  let newValue = value;
  if (decimalSeparator && decimalSeparator !== '.') {
    newValue = newValue.replace(
      RegExp(escapeRegExp(decimalSeparator), 'g'),
      '.'
    );
    if (isNegative && decimalSeparator === '-') {
      newValue = `-${newValue.slice(1)}`;
    }
  }
  return newValue;
};

const replaceParts = (
  parts: Intl.NumberFormatPart[],
  {
    prefix,
    groupSeparator,
    decimalSeparator,
    decimalScale,
  }: Pick<
    FormatValueOptions,
    'prefix' | 'groupSeparator' | 'decimalSeparator' | 'decimalScale'
  >
): string => {
  return parts
    .reduce(
      (prev, { type, value }, i) => {
        if (i === 0 && prefix) {
          if (type === 'minusSign') {
            return [value, prefix];
          }

          if (type === 'currency') {
            return [...prev, prefix];
          }

          return [prefix, value];
        }

        if (type === 'currency') {
          return prefix ? prev : [...prev, value];
        }

        if (type === 'group') {
          return [
            ...prev,
            groupSeparator !== undefined ? groupSeparator : value,
          ];
        }

        if (type === 'decimal') {
          if (decimalScale !== undefined && decimalScale === 0) {
            return prev;
          }

          return [
            ...prev,
            decimalSeparator !== undefined ? decimalSeparator : value,
          ];
        }

        if (type === 'fraction') {
          return [
            ...prev,
            decimalScale !== undefined ? value.slice(0, decimalScale) : value,
          ];
        }

        return [...prev, value];
      },
      ['']
    )
    .join('');
};

export const padTrimValue = (
  value: string,
  decimalSeparator = '.',
  decimalScale?: number
): string => {
  if (decimalScale === undefined || value === '' || value === undefined) {
    return value;
  }

  if (!value.match(/\d/g)) {
    return '';
  }

  const [int, decimals] = value.split(decimalSeparator);

  if (decimalScale === 0) {
    return int;
  }

  let newValue = decimals || '';

  if (newValue.length < decimalScale) {
    while (newValue.length < decimalScale) {
      newValue += '0';
    }
  } else {
    newValue = newValue.slice(0, decimalScale);
  }

  return `${int}${decimalSeparator}${newValue}`;
};

export const removeInvalidChars = (
  value: string,
  validChars: ReadonlyArray<string>
): string => {
  const chars = escapeRegExp(validChars.join(''));
  const reg = new RegExp(`[^\\d${chars}]`, 'gi');
  return value.replace(reg, '');
};

export const removeSeparators = (value: string, separator = ','): string => {
  const reg = new RegExp(escapeRegExp(separator), 'g');
  return value.replace(reg, '');
};

import Dropdown from 'components/Dropdown/Dropdown';
import styled from 'styled-components';
import './Select.scss';
import { ReactComponent as ArrowIcon } from 'common/src/icons/arrow-down.svg';
import { SelectOption } from 'common/src/types';

interface Props {
  options: SelectOption[];
  onSelect: (v: string) => () => void;
  placeholder?: string;
  value: string | undefined;
  error?: boolean | string | undefined;
}

export default function Select({
  options,
  onSelect,
  placeholder = 'Select',
  value,
  error,
}: Props) {
  const curLabel = options.find((o) => o.value === value)?.label;
  return (
    <Dropdown
      horizontalAlignment='left'
      className='Select'
      trigger={
        <div>
          <Trigger
            className='d-flex justify-content-between align-items-center'
            error={error}
          >
            {curLabel || placeholder} <ArrowIcon />
          </Trigger>
          {error && <div className='Input__error text-end'>{error}</div>}
        </div>
      }
      content={() => (
        <>
          <div className='mb-n3'>
            {options.map((v) => (
              <div
                className='mb-3 Select__item transition-color'
                key={v.value}
                onClick={onSelect(v.value)}
              >
                {v.label}
              </div>
            ))}
          </div>
        </>
      )}
    />
  );
}

const Trigger = styled.div<{ error?: boolean | string | undefined }>`
  padding: 13px 20px;
  color: #8d89a3;
  background-color: #fff;
  border: ${({ error }) => (error ? '1px solid #f05a62' : '1px solid #ebeaeb')};
  border-radius: 10px;
  outline: none;
  transition: border-color 0.25s;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
`;

import { createSlice } from "@reduxjs/toolkit";

type ReportRecommendation = {
  id?: number;
  in_action?: boolean;
  actionable: boolean;
  msg: string;
};

interface ReportsState {
  reports: {
    improvement: string[];
    well: string[];
    recommendations: ReportRecommendation[];
    recommendations2: ReportRecommendation[];
  };
}

const initialState: ReportsState = {
  reports: {
    improvement: [],
    well: [],
    recommendations: [],
    recommendations2: [],
  },
};

export const reportsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {
    updateReports: (state, action) => {
      state.reports = {
        ...state.reports,
        improvement: action.payload.improvement,
        well: action.payload.well,
        recommendations: action.payload.recommendations,
        recommendations2: action.payload.recommendations2,
      };
    },
  },
});

export const { updateReports } = reportsSlice.actions;

export default reportsSlice.reducer;

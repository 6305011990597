import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "common/src/hooks";
import { updateMetrics } from "common/src/store/metrics/metricsSlice";
import apiClient from "core/apiClient";
import { format } from "date-fns";
import { formatNumber } from "helpers";
import { Title, Text } from "pages/Dashboard/Dashboard";
import WantMore from "pages/Dashboard/WantMore";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Progress } from "../../components/Tile/Tile";
import { toast, ToastContainer } from "react-toastify";

export default function KeyMetrics() {
  const dispatch = useAppDispatch();
  const metrics = useAppSelector((s) => s.metrics.metrics);
  const [isLoading, setIsLoading] = useState<boolean>(
    metrics.total_debt === 0 ? true : false
  );

  useEffect(() => {
    if (!metrics.total_debt) {
      apiClient
        .getMetrics()
        .then((res) => {
          setIsLoading(false);
          dispatch(updateMetrics(res));
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data, {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          })
          console.log(err);
        });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Diane Money | Key Metrics</title>
      </Helmet>
      <CircleSpinnerOverlay
        loading={isLoading}
        overlayColor="rgba(16, 8, 64, 0.2)"
        color="rgb(16, 8, 64)"
      />
      <Container className="w-100 mx-auto">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="mb-4">
          <Title className="fw-800 text-gray">Key Metics </Title>
          <Text className="text-gray">| {format(new Date(), "MMMM")}</Text>
        </div>
        {/* <Tile className="d-flex align-items-center mb-4">
          <div className="pe-4 me-3">
            <TileLabel className="text-gray mb-3">
              Primary Goal is Met in
            </TileLabel>
            <TileTitle className="fw-800">2 Months</TileTitle>
          </div>
          <div
            className="flex-grow-1"
            style={{
              maxWidth: 446,
            }}
          >
            <Progress value={28} />
          </div>
        </Tile> */}
        <Tile className="d-flex align-items-center justify-content-center mb-4">
          <Row className="row row-cols-auto justify-content-center">
            <Col className="col">
              <div className="text-gray mb-4 text-center">Net</div>
              <TileTitle
                className="fw-800 d-flex align-items-start justify-content-center"
                color="#75CE8C"
              >
                {formatNumber(0.0, {
                  currency: "usd",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
                <Badge>{Number(0.0).toFixed(2).slice(-3)}</Badge>
              </TileTitle>
            </Col>
            <Col className="col">
              <div className="text-gray mb-4 text-center">Outflow</div>

              <TileTitle
                className="fw-800 d-flex align-items-start justify-content-center"
                color="#F05A62"
              >
                {formatNumber(metrics.monthly_outflow, {
                  currency: "usd",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
                <Badge>
                  {Number(metrics.monthly_outflow).toFixed(2).slice(-3)}
                </Badge>
              </TileTitle>
            </Col>
            <Col className="col">
              <div className="text-gray mb-4 text-center">Income</div>
              <TileTitle
                className="fw-800 d-flex align-items-start justify-content-center"
                color="#75CE8C"
              >
                +
                {formatNumber(metrics.monthly_inflow, {
                  currency: "usd",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
                <Badge>
                  {Number(metrics.monthly_inflow).toFixed(2).slice(-3)}
                </Badge>
              </TileTitle>
            </Col>
          </Row>
        </Tile>
        <Tile>
          <Row className="row row-cols-auto justify-content-center">
            <Col className="col">
              <div className="text-gray mb-4 text-center">Total Debt</div>
              <TileTitle
                className="fw-800 d-flex align-items-start justify-content-center"
                color="#F05A62"
              >
                {formatNumber(metrics.total_debt, {
                  currency: "usd",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
                <Badge>{Number(metrics.total_debt).toFixed(2).slice(-3)}</Badge>
              </TileTitle>
            </Col>
            <Col className="col">
              <div className="text-gray mb-4 text-center">Debt Free in</div>
              <TileTitle
                className="fw-800 d-flex align-items-start justify-content-center"
                color="#8D89A3"
              >
                {metrics.months_until_debt_free
                  ? metrics.months_until_debt_free == 1 ? `${metrics.months_until_debt_free} Month` : `${metrics.months_until_debt_free} Months`
                  : `Debt Free`}
              </TileTitle>
            </Col>
            <Col className="col">
              <div className="text-gray mb-4 text-center">
                Min. Monthly Payment
              </div>
              <TileTitle
                className="fw-800 d-flex align-items-start justify-content-center"
                color="#8D89A3"
              >
                {formatNumber(metrics.total_minimum_debt_payment, {
                  currency: "usd",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
                <Badge>
                  {Number(metrics.total_minimum_debt_payment)
                    .toFixed(2)
                    .slice(-3)}
                </Badge>
              </TileTitle>
            </Col>
          </Row>
        </Tile>
      </Container>
      <div
        style={{
          paddingTop: 153,
        }}
      >
        <WantMore />
      </div>
    </>
  );
}

const Tile = styled.div`
  border: 1px solid #ebeaeb;
  border-radius: 10px;
  min-height: 200px;
  padding: 57px 50px;
  background-color: #fff;
`;

const TileLabel = styled.div`
  font-size: 18px;
  line-height: 17px;
`;
const TileTitle = styled.div<{ color?: string }>`
  font-size: 38px;
  line-height: 37px;
  color: ${(props) => props.color || "#100840"};
`;
const Badge = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-top: -6px;
`;

const Col = styled.div`
  padding: 0 50px;
  &:not(:last-child) {
    border-right: 1px solid #ebeaeb;
  }
`;
const Row = styled.div`
  margin: 0 -50px;
`;

export const Container = styled.div`
  max-width: 908px;
  padding-top: 90px;
`;

import classNames from 'classnames';
import { Button } from 'components-styled/Button';
import Modal, { ModalProps } from 'components/Modal';
import styled from 'styled-components';

interface Props {
  image?: string;
}

export default function DashboardModal({
  isOpened,
  closeModal,
  image,
}: ModalProps & Props) {
  return (
    <Modal isOpened={isOpened} closeModal={closeModal}>
      <div
        className={classNames('d-flex flex-column align-items-center', {
          'pt-2': image,
          'pt-5': !image,
        })}
      >
        {image && (
          <img
            className='mb-2'
            src={image}
            alt='entity'
            style={{
              width: 180,
              height: 180,
            }}
          />
        )}
        <Title className='text-uppercase fw-800 mb-3'>
          We’re generating your first report now.
        </Title>
        <div className='text-center text-gray mx-4 mb-4 pb-2'>
          In the meantime, take a look around at your financial health.
        </div>
        <Button>Sounds Good!</Button>
      </div>
    </Modal>
  );
}

const Title = styled.span`
  font-size: 24px;
  line-height: 30px;
`;

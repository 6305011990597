import { AxiosStatic } from "axios";
import {
  CreateDebtInput,
  CreateGoalInput,
  LinkToken,
  PatchGoalInput,
} from "./types";
const API_URL = process.env.REACT_APP_API_URL;
const SECRET_API_KEY = process.env.REACT_APP_SHARED_SECRET_API_KEY;
const S3_BUCKET = process.env.REACT_APP_S3_BUCKET || '';

// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (err) {
//     return Promise.reject(err);
//   }
// );

export class API {
  axios;

  constructor(axios: AxiosStatic) {
    this.axios = axios;
  }

  async getLinkToken() {
    const res = await this.axios.post<LinkToken>(
      // `${API_URL}/v1/plaid/sandbox/public_token`
      `${API_URL}/v1/plaid/create_link_token`
    );
    return res.data;
  }

  async setAccessToken(public_token: string) {
    const formData = new FormData();

    formData.append("public_token", public_token);

    const res = await this.axios.post<LinkToken>(
      `${API_URL}/v1/plaid/set_access_token`,
      formData
    );

    return res.data;
  }

  async deleteAccount(userId?: string) {
    const res = await this.axios.delete(`${API_URL}/v1/users/delete-request`);
    return res.data;
  }

  async createGoal(data: CreateGoalInput) {
    const formData = new FormData();
    formData.append("amount", data.amount);
    formData.append("saving", data.saving);
    formData.append("name", data.name);
    formData.append("is_primary", data.is_primary);
    formData.append("target_date", data.target_date);
    formData.append("image_url", data.image_url);

    // should be deleted
    formData.append("user_id", data.user_id);

    const res = await this.axios.post(`${API_URL}/v1/goals`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return res?.data;
  }

  async uploadGoalImage(file: string, filename: string) {
    const formData = new FormData();
    formData.append("filename", filename);
    formData.append("bucket", S3_BUCKET);
    formData.append("file", file);

    const res = await this.axios.post(`${API_URL}/v1/goals/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return res?.data;
  }

  async patchGoal(data: any) {
    const formData = new FormData();
    formData.append("amount", data.amount);
    formData.append("saving", data.saving);
    formData.append("name", data.name);
    formData.append("is_primary", data.is_primary);
    formData.append("target_date", data.target_date);
    formData.append("image_url", data.image_url);

    const res = await this.axios.patch(
      `${API_URL}/v1/goals/${data.id}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    return res.data;
  }

  async updateGoal(data: any) {
    const formData = new FormData();
    formData.append("amount", data.amount);
    formData.append("saving", data.saving);
    formData.append("name", data.name);
    formData.append("is_primary", data.is_primary);
    formData.append("target_date", data.target_date);
    formData.append("image_url", data.image_url);

    const res = await this.axios.put(
      `${API_URL}/v1/goals/${data.id}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    return res.data;
  }

  async deleteGoal(goalId: number) {
    const res = await this.axios.delete(`${API_URL}/v1/goals/${goalId}`);
    return res.data;
  }

  async createDebt(data: CreateDebtInput) {
    const formData = new FormData();
    formData.append("total_amount", data.total_amount);
    formData.append("total_paid", data.total_paid);
    formData.append("interest_rate", data.interest_rate);
    formData.append("monthly_payment", data.monthly_payment);
    formData.append("name", data.name);
    formData.append("type", data.type);

    // should be deleted
    formData.append("user_id", data.user_id);

    const res = await this.axios.post(`${API_URL}/v1/debts`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return res.data;
  }

  async patchDebt(data: any) {
    const formData = new FormData();

    formData.append("total_amount", data.total_amount);
    formData.append("total_paid", data.total_paid);
    formData.append("interest_rate", data.interest_rate);
    formData.append("monthly_payment", data.monthly_payment);
    formData.append("name", data.name);
    formData.append("type", data.type);

    const res = await this.axios.patch(
      `${API_URL}/v1/debts/${data.id}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    return res.data;
  }

  async getDebts() {
    const res = await this.axios.get(`${API_URL}/v1/debts`);
    return res.data;
  }

  async deleteDebt(debtId: number) {
    const res = await this.axios.delete(`${API_URL}/v1/debts/${debtId}`);
    return res.data;
  }

  async getGoalStatus(goalId: string) {
    const res = await this.axios.get(`${API_URL}/v1/goals/status/${goalId}`);
    return res.data;
  }

  async getGoals() {
    const res = await this.axios.get(`${API_URL}/v1/goals`);
    return res.data;
  }

  async getReport() {
    const res = await this.axios.get(`${API_URL}/v1/reports`);
    return res.data;
  }

  async setIsTakingReportAction(id: number) {
    const res = await this.axios.post(`${API_URL}/v1/reports/${id}`);
    return res.data;
  }

  async getMetrics() {
    const res = await this.axios.get(`${API_URL}/v1/stats`);
    return res.data;
  }

  async confirmSignUp(email: string, idToken: string, referrer_code: string = '') {
    const formData = new FormData();
    formData.append("email", email);

    if (referrer_code.length) {
      formData.append("referrer_code", referrer_code);
    }

    const res = await this.axios.post(`${API_URL}/v1/users`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": SECRET_API_KEY ?? "",
        "x-id-token": idToken,
      },
    });
    return res.data;
  }

  async getUser() {
    const res = await this.axios.get(`${API_URL}/v1/users`);
    return res.data;
  }

  async setPurchase(data: any) {
    const formData = new FormData();
    formData.append("orderId", data.orderId);
    formData.append("purchaseToken", data.purchaseToken);
    formData.append("transactionReceipt", data.transactionReceipt);
    formData.append("platform", data.platform);
    const res = await this.axios.post(`${API_URL}/v1/users/purchase`, formData);
    return res.data;
  }

  async login(username: string, password: string) {
    const res = await this.axios.post(`${API_URL}/v1/users/login`, { username, password });
    return res.data;
  }
}

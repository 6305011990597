import classNames from "classnames";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import { Route, RouteProps, Routes, useLocation } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import UpgradePlan from "./UpgradePlan";
import KeyMetrics from "./KeyMetrics/KeyMetrics";
import YourPlan from "./YourPlan/YourPlan";
import Profile from "./Profile/Profile";
import EditDebt from "./Dashboard/EditDebt";
import EditGoal from "./Dashboard/EditGoal";
import CreateDebt from "./Onboarding/CreateDebt";
import CreateGoal from "./Onboarding/CreateGoal";

export enum RoutesEnum {
  metrics = "/metrics",
  profile = "/profile",
  proPlan = "/pro-plan",
  createGoal = "/create-goal",
  editGoal = "/edit-goal",
  createDebt = "/create-debt",
  editDebt = "/edit-debt",
  yourPlan = "/your-plan",
}

const routes: RouteProps[] = [
  {
    element: <Dashboard />,
    path: "/",
  },
  {
    element: <UpgradePlan />,
    path: RoutesEnum.proPlan,
  },
  {
    element: <KeyMetrics />,
    path: RoutesEnum.metrics,
  },
  {
    element: <YourPlan />,
    path: RoutesEnum.yourPlan,
  },
  {
    element: <Profile />,
    path: RoutesEnum.profile,
  },
  {
    element: <EditDebt />,
    path: RoutesEnum.editDebt,
  },
  {
    element: <CreateGoal footer={false}/>,
    path: RoutesEnum.createGoal,
  },
  {
    element: <CreateDebt />,
    path: RoutesEnum.createDebt,
  },
  {
    element: <EditGoal />,
    path: RoutesEnum.editGoal,
  },
];

export default function DashboardRoutes() {
  const location = useLocation();

  return (
    <div
      className={classNames("d-flex flex-column min-vh-100", {
        "bg-dashboard": location.pathname !== RoutesEnum.proPlan,
        "bg-upgrade": location.pathname === RoutesEnum.proPlan,
      })}
    >
      <div className="flex-grow-1 d-flex">
        <Sidebar />
        <div className="d-flex flex-column flex-grow-1 px-3">
          <Routes>
            {routes.map((r) => (
              <Route path={r.path} element={r.element} key={r.path} />
            ))}
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import { useState } from "react";
import { FormikErrors, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

/** Presentational */
import { Button } from "components-styled/Button";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import Input from "components/Input/Input";
import LinkButton from "components/LinkButton";
import Select from "components/Select/Select";
import { OnboardingCard } from "components-styled/Card";
import { CloseButton } from "components-styled/Button";
import { ReactComponent as CrossIcon } from "common/src/icons/cross.svg";

/** Containers */
import LoginContainer from "containers/LoginContainer";

/** Hooks */
import { useAppSelector } from "common/src/hooks";

/** constants */
import { DEBT_TYPE_OPTIONS } from "utils/constants";

import mixpanel from "utils/mixpanel"

/** Api */
import apiClient from "core/apiClient";
interface Props {
  onClickSave?: () => void;
  onClickSkip?: () => void;
}

interface FormikValues {
  name: string;
  type: string;
  amount: number;
  total_paid: number;
  interest_rate: number;
  monthly_payment: number;
}

export default function EditDebt({ onClickSave, onClickSkip }: Props) {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isOnboarding = useAppSelector((s) => s.user.onboarding);
  const debt = useAppSelector((s) => s.debts.editDebt);
  const {
    id,
    name,
    interest_rate,
    total_amount,
    total_paid,
    monthly_payment,
    type,
  } = debt;

  const formik = useFormik({
    initialValues: {
      name,
      type,
      amount: total_amount,
      monthly_payment,
      total_paid,
      interest_rate: Number(parseFloat(interest_rate.toString()).toFixed(2)),
    },
    validate: () => {
      const errors: FormikErrors<FormikValues> = {};
      return errors;
    },
    onSubmit: (values: FormikValues) => {
      setIsLoading(true);
      apiClient
        .patchDebt({
          id,
          total_amount: values.amount,
          total_paid: values.total_paid,
          monthly_payment: values.monthly_payment,
          name: values.name,
          interest_rate: values.interest_rate,
          type: values.type,
        })
        .then((res) => {
          mixpanel.track("Debt edited")
          setIsLoading(false);
          onClickClose();
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
        });
    },
  });

  const onFieldChange: (field: string) => (v: string | undefined) => void =
    (field) => (v) => {
      setTimeout(() => {
        formik.setFieldValue(field, v, true);
        formik.setFieldTouched(field, true, true);
      }, 10);
    };

  const onClickClose = () => {
    navigate("/");
  };

  return (
    <LoginContainer footer={false}>
      <OnboardingCard
        className={classNames("mx-auto", {
          "pt-4": !isOnboarding,
        })}
      >
        {!isOnboarding && (
          <div className="text-end mb-2">
            <CloseButton
              className="p-0 border-0 p-0 bg-transparent cursor-pointer"
              onClick={onClickClose}
            >
              <CrossIcon />
            </CloseButton>
          </div>
        )}
        <div className="text-uppercase mb-2 text--24 fw-800 text-center">
          Edit debt {name}
        </div>
        <form
          style={{ maxWidth: 380 }}
          className="mx-auto w-100"
          onSubmit={formik.handleSubmit}
        >
          <Input
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            placeholder="Debt Name"
            className="pb-4"
          />
          <Select
            value={formik.values.type}
            options={DEBT_TYPE_OPTIONS}
            onSelect={(v: string) => () => {
              formik.setFieldValue("type", v, true);
              formik.setFieldTouched("type", true, true);
            }}
            placeholder="Debt Type"
          />
          <CurrencyInput
            name="amount"
            value={formik.values.amount}
            onValueChange={onFieldChange("amount")}
            placeholder="Total Amount"
            className="my-4"
          />
          <CurrencyInput
            name="paid"
            value={formik.values.total_paid}
            onValueChange={onFieldChange("total_paid")}
            placeholder="Total Paid"
            className="my-4"
          />
          <Input
            type="number"
            step={0.5}
            onBlur={(e) => {
              (e.target as HTMLInputElement).type = "text";
              if (formik.values.interest_rate) {
                formik.setFieldValue(
                  "interest_rate",
                  formik.values.interest_rate + "%"
                );
              }
            }}
            onFocus={(e) => {
              (e.target as HTMLInputElement).type = "number";
              formik.setFieldValue(
                "interest_rate",
                formik.values.interest_rate.toString().replace("%", "")
              );
            }}
            name="interest_rate"
            value={formik.values.interest_rate.toString()}
            onChange={formik.handleChange}
            placeholder="Interest Rate"
            className="pb-4"
            max={100}
          />
          <CurrencyInput
            name="monthly_payment"
            value={formik.values.monthly_payment}
            onValueChange={onFieldChange("monthly_payment")}
            placeholder="Monthly Payment"
          />
          <div className="row row-cols-auto gx-5 align-items-center mt-5 justify-content-center">
            <div className="col mx-1">
              <Button type="submit" disabled={isLoading}>
                {isLoading ? `Editing Debt...` : `Edit Debt`}
              </Button>
            </div>
            {onClickSkip && (
              <div className="col mx-1">
                <LinkButton type="button" onClick={onClickSkip}>
                  Skip for now
                </LinkButton>
              </div>
            )}
          </div>
        </form>
      </OnboardingCard>
    </LoginContainer>
  );
}

import { MutableRefObject, useState } from 'react';
import { useDebounce } from 'common/src/hooks';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

export default function useDropdown<T>(
  ref: MutableRefObject<T>,
  isDisabled?: boolean
) {
  const [isOpened, setIsOpened] = useState(false);
  const debouncedIsOpened = useDebounce(isOpened, 250);
  const isDropdownOpened = isOpened || debouncedIsOpened;

  const toggleDropdown = () => {
    if (!isDisabled) {
      setIsOpened(!isOpened);
    }
  };

  const onClickOutside = () => {
    if (!isOpened) return;
    setIsOpened(false);
  };

  useOnClickOutside(ref, onClickOutside);
  return { toggleDropdown, isDropdownOpened, isOpened, isDisabled };
}

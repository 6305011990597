import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 10px;
  background: #ffffff;
`;

export const LoginCard = styled(Card)`
  color: #8d89a3;
  a {
    color: #5959e3;
    &:hover {
      color: #353192;
    }
  }
  max-width: 625px;
`;

export const WelcomeCard = styled(Card)`
  border: 1px solid #ebeaeb;
  padding: 30px 30px 60px;
  max-width: 874px;
`;

export const OnboardingCard = styled(WelcomeCard)`
  padding: 65px 30px 60px;
`;

import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import metricsReducer from "./metrics/metricsSlice";
import reportsReducer from "./reports/reportsSlice";
import goalsReducer from "./goals/goalsSlice";
import debtsReducer from "./debts/debtsSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    goals: goalsReducer,
    debts: debtsReducer,
    metrics: metricsReducer,
    reports: reportsReducer,
  },
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

import { ChangeEventHandler } from 'react';
import cn from 'classnames';
import './Checkbox.scss';
import { ReactComponent as TipIcon } from 'common/src/icons/checkbox-tip.svg';

interface Props {
  id?: string;
  name?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isError?: boolean;
  labelId?: string;
  label: JSX.Element | string;
}

const Checkbox = ({
  checked,
  onChange,
  onBlur,
  className = '',
  isError = false,
  disabled = false,
  id,
  name,
  label,
  labelId = '',
}: Props) => {
  return (
    <div className={`Checkbox ${className}`}>
      <label
        className={cn('d-flex Checkbox__container align-items-center', {
          Checkbox__error: isError,
        })}
        data-testid={name}
      >
        <input
          id={id}
          name={name}
          type='checkbox'
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          onBlur={onBlur}
          data-testid={'checkbox_' + name}
        />
        <div className='Checkbox__input Checkbox__input--checkbox rounded-circle position-relative me-2'>
          {checked && <TipIcon className='position-absolute' />}
        </div>
        <div className='Checkbox__label' id={labelId}>
          {label}
        </div>
      </label>
    </div>
  );
};

export default Checkbox;

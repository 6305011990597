import { Button } from 'components-styled/Button';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from "common/src/hooks";
import { ReactComponent as Checkmark } from 'common/src/icons/checkmark.svg';
import { Link } from 'react-router-dom';

import mixpanel from "utils/mixpanel"

const POINTS = [
  'Get monthly or daily updates to your financial life.',
  'Get full access to Diane’s reports.',
  'Get full access to Diane’s financial recommendations.',
];

export default function Proplan({buttonAlign = 'center'}) {
  const user = useAppSelector((s) => s.user.user);

  const onManageSubscription = () => {
    window.open('https://billing.stripe.com/p/login/aEU15Cf9Q1WcfXq7ss', '_blank');
  }

  return (
    <div>
      <div className='d-flex justify-content-between aling-items-start mb-4'>
        <div>
          <PlanTitle className='fw-800'>Pro Plan</PlanTitle>
          <div className='text-gray'>Includes</div>
        </div>
        {/* <div className='text-gray fw-800 pe-2'>$10/Month</div> */}
      </div>
      {POINTS.map((p, i) => (
        <div key={i} className='mb-3 d-flex align-items-start'>
          <Checkmark className='me-2' />
          {p}
        </div>
      ))}
      <div className={'text-' + buttonAlign + ' pt-2 pb-1'}>
        {
          user.isPremium
          ? <Button type='button' onClick={onManageSubscription}>Manage Subscription</Button>
          : <Link to='/pro-plan' onClick={()=> mixpanel.track("Upgrade to Pro")}><Button type='button'>Upgrade to Pro</Button></Link>
        }
      </div>
    </div>
  );
}

const PlanTitle = styled.div`
  font-size: 38px;
  line-height: 37px;
`;

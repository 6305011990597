import styled from "styled-components";

export const Button = styled.button`
  padding: 15px 30px;
  font-weight: 800;
  background-color: var(--bs-primary);
  color: #fff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 17px;
  outline: none;
  border: none;
  transition: color 0.25s;
  transition: background-color 0.25s;
  cursor: pointer;

  &:hover {
    background-color: #353192;
  }
  &:focus {
    background-color: #100840;
  }
`;

export const ButtonIcon = styled(Button)`
  border: 3px solid var(--bs-primary);
  padding: 16px 30px;
  transition: background-color 0.25s, border-color 0.25s;

  &:hover {
    border-color: #353192;
  }
  &:focus {
    border-color: #100840;
  }
`;

export const ButtonOutline = styled(Button)`
  padding: 15px 30px;

  border: 3px solid var(--bs-primary);
  background-color: transparent;
  color: var(--bs-primary);
  transition: color 0.25s, border-color 0.25s;

  &:hover {
    background-color: transparent;
    border-color: #353192;
    color: #353192;
  }
  &:focus {
    background-color: transparent;
    border-color: #100840;
    color: #100840;
  }
`;

export const ButtonOutlineIcon = styled(ButtonOutline)`
  padding: 16px 30px;

  svg {
    path {
      transition: fill 0.25s;
    }
  }

  &:hover {
    svg {
      path {
        fill: #353192;
      }
    }
  }
  &:focus {
    svg {
      path {
        fill: #100840;
      }
    }
  }
`;

export const CloseButton = styled.button`
  svg {
    transition: transform 250ms;
  }
  &:hover {
    svg {
      transform: rotate(180deg);
    }
  }
`;

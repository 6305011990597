import { createSlice } from "@reduxjs/toolkit";

type GoalType = {
  id: number;
  amount: number;
  saving: number;
  name: string;
  target_date: string;
  image_url: string;
  image_presigned_url: string;
  is_primary: boolean;
  report: any;
  total_amount: number;
  contribution: number;
};
interface GoalsState {
  goals: GoalType[];
  editGoal: {
    id: number;
    amount: number;
    saving: number;
    name: string;
    target_date: string;
    image_url: string;
    image_presigned_url: string;
    is_primary: boolean;
  };
}

const initialState: GoalsState = {
  goals: [],
  editGoal: {
    id: 0,
    amount: 0,
    saving: 0,
    name: "",
    target_date: "",
    image_url: "",
    image_presigned_url: "",
    is_primary: false,
  },
};

export const goalsSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateGoals: (state, action) => {
      state.goals = action.payload;
    },
    setEditGoal: (state, action) => {
      state.editGoal = {
        ...state.editGoal,
        id: action.payload.id,
        name: action.payload.name,
        amount: action.payload.amount,
        saving: action.payload.saving,
        target_date: action.payload.target_date,
        image_url: action.payload.image_url,
        image_presigned_url: action.payload.image_presigned_url,
      };
    },
  },
});

export const { updateGoals, setEditGoal } = goalsSlice.actions;

export default goalsSlice.reducer;

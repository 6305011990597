import { useState } from 'react';

export function useTabs<T>(initialTab: T) {
  const [activeTab, setActiveTab] = useState(initialTab);

  const onTabClick = (id: T) => () => {
    setActiveTab(id);
  };

  return { activeTab, onTabClick };
}

import Dropdown from "../Dropdown/Dropdown";
import { ReactComponent as CalendarIcon } from "common/src/icons/calendar.svg";
import "./DatePicker.scss";
import format from "date-fns/format";
import { isValid } from "date-fns";
import DatePickerCalendar from "./DatePickerCalendar";
import classNames from "classnames";
import Input from "components/Input/Input";
import React, { useEffect, useState } from "react";

interface Props {
  selectedDate?: any;
  onSelect: (d: Date | null) => void;
  initialDate?: Date;
  name?: string;
  label?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  horizontalAlignment?: "left" | "right";
  direction?: "up" | "down";
  error?: string | boolean | undefined;
  deletable?: boolean;
}

export default function DatePicker({
  selectedDate,
  onSelect,
  initialDate,
  name,
  label,
  className = "",
  placeholder = "Deadline",
  horizontalAlignment = "left",
  disabled,
  direction = "down",
  error,
  deletable,
}: Props) {
  const handleSelect = (toggleDropdown: () => void) => (d: Date) => {
    onSelect(d);
    toggleDropdown();
  };
  const [date, setDate] = useState(format(isValid(selectedDate) ? new Date(selectedDate) : new Date(), "MM/dd/yyy"));

  useEffect(() => {
    if (
      date.length === 10 &&
      selectedDate !== null &&
      format(new Date(date), "MM/dd/yyy") !== format(selectedDate, "MM/dd/yyyy")
    ) {
      onSelect(new Date(date));
    }
  }, [date]);

  useEffect(() => {
    if (isValid(selectedDate) && format(selectedDate, "MM/dd/yyyy") !== date) {
      setDate(format(selectedDate, "MM/dd/yyyy"));
    }
  }, [selectedDate]);

  return (
    <div className={`${className} position-relative`}>
      {!!label && <div className="mb-2 DatePicker__label">{label}</div>}
      <div className="d-flex align-items-center">
        <Dropdown
          isDisabled={disabled}
          direction={direction}
          className={classNames("DatePicker", {
            "DatePicker--error": !!error,
          })}
          data-testid={name ? name : undefined}
          trigger={
            <div className="w-100 d-flex justify-content-between align-items-center text-gray">
              {/* {selectedDate ? format(selectedDate, "MM/dd/yyyy") : placeholder}{" "} */}
              <Input
                name="date"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDate(e.target.value);
                }}
                value={date}
              />
              <CalendarIcon />
            </div>
          }
          content={(toggleDropdown) => (
            <>
              <DatePickerCalendar
                initialDate={initialDate}
                selectedDate={selectedDate}
                onSelect={handleSelect(toggleDropdown)}
                name={name ? `${name}-calendar` : undefined}
              />
              {deletable && (
                <div className="ps-2 ms-1">
                  <button
                    className="btn btn--naked btn--naked--blue"
                    onClick={() => {
                      onSelect(null);
                      toggleDropdown();
                    }}
                  >
                    Clear
                  </button>
                </div>
              )}
            </>
          )}
          horizontalAlignment={horizontalAlignment}
        />
      </div>
      {error && (
        <div
          className="DatePicker__error text-end"
          data-testid="trespassing_delivery_threshold_date-error"
        >
          {error}
        </div>
      )}
    </div>
  );
}

import classNames from 'classnames';
import { useState, useEffect, useRef, useMemo } from 'react';

import {
  cleanValue,
  formatValue,
  getLocaleConfig,
  padTrimValue,
  CleanValueOptions,
  FormatValueOptions,
  repositionCursor,
  CurrencyInputProps,
  CurrencyInputOnChangeValues,
} from './CarencyInput.helpers';

export const CurrencyInput = ({
  id,
  name,
  className = '',
  disabled = false,
  value: userValue,
  onValueChange,
  placeholder,
  decimalScale = 2,
  intlConfig,
  onFocus,
  onBlur,
  label,
  error,
  ...props
}: CurrencyInputProps) => {
  const localeConfig = useMemo(() => getLocaleConfig(intlConfig), [intlConfig]);
  const decimalSeparator = localeConfig.decimalSeparator || '';
  const groupSeparator = localeConfig.groupSeparator || '';

  const formatValueOptions: Partial<FormatValueOptions> = {
    decimalSeparator,
    groupSeparator,
    intlConfig,
    prefix: localeConfig.prefix,
  };

  const cleanValueOptions: Partial<CleanValueOptions> = {
    decimalSeparator,
    groupSeparator,
    decimalsLimit: 2,
    prefix: localeConfig.prefix,
  };

  const formattedStateValue = userValue
    ? formatValue({
        ...formatValueOptions,
        decimalScale,
        value: String(userValue),
      })
    : '';

  const [stateValue, setStateValue] = useState(formattedStateValue);
  const [dirty, setDirty] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [changeCount, setChangeCount] = useState(0);
  const [lastKeyStroke, setLastKeyStroke] = useState<string | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  const processChange = (
    value: string,
    selectionStart?: number | null
  ): void => {
    setDirty(true);

    const { modifiedValue, cursorPosition } = repositionCursor({
      selectionStart,
      value,
      lastKeyStroke,
      stateValue,
      groupSeparator,
    });

    const stringValue = cleanValue({
      value: modifiedValue,
      ...cleanValueOptions,
    });

    if (
      stringValue === '' ||
      stringValue === '-' ||
      stringValue === decimalSeparator
    ) {
      onValueChange(undefined, name, {
        float: null,
        formatted: '',
        value: '',
      });
      setStateValue(stringValue);
      return;
    }

    const numberValue = parseFloat(stringValue.replace(decimalSeparator, '.'));

    const formattedValue = formatValue({
      value: stringValue,
      ...formatValueOptions,
    });

    if (cursorPosition !== undefined && cursorPosition !== null) {
      // Prevent cursor jumping
      let newCursor = cursorPosition + (formattedValue.length - value.length);
      newCursor = newCursor <= 0 ? 0 : newCursor;

      setCursor(newCursor);
      setChangeCount(changeCount + 1);
    }

    setStateValue(formattedValue);

    const values: CurrencyInputOnChangeValues = {
      float: numberValue,
      formatted: formattedValue,
      value: stringValue,
    };
    onValueChange(stringValue, name, values);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value, selectionStart },
    } = e;
    processChange(value, selectionStart);
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>): number => {
    if (onFocus) onFocus(e);
    return stateValue.length || 0;
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = e;

    const val = cleanValue({ value, ...cleanValueOptions });

    if (val === '-' || !val) {
      setStateValue('');
      if (onBlur) onBlur(e);
      return;
    }

    const newValue = padTrimValue(val, decimalSeparator, decimalScale);

    const numberValue = +newValue.replace(decimalSeparator, '.');

    const formattedValue = formatValue({
      ...formatValueOptions,
      value: newValue,
    });

    onValueChange(newValue, name, {
      float: numberValue,
      formatted: formattedValue,
      value: newValue,
    });

    setStateValue(formattedValue);
    if (onBlur) onBlur(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setLastKeyStroke(e.key);
  };

  useEffect(() => {
    // prevent cursor jumping if editing value
    if (
      dirty &&
      stateValue !== '-' &&
      ref &&
      typeof ref === 'object' &&
      ref.current
    ) {
      ref.current.setSelectionRange(cursor, cursor);
    }
  }, [stateValue, cursor, ref, dirty, changeCount]);

  const getRenderValue = () => {
    if (
      userValue !== undefined &&
      userValue !== null &&
      stateValue !== '-' &&
      (!decimalSeparator || stateValue !== decimalSeparator)
    ) {
      return formatValue({
        ...formatValueOptions,
        decimalScale: dirty ? undefined : decimalScale,
        value: String(userValue),
      });
    }

    return stateValue;
  };

  return (
    <div className={`Input ${className}`}>
      {label && (
        <div className='Input__label' data-testid={`${name}-label`}>
          {label}
        </div>
      )}
      <input
        {...props}
        type='text'
        inputMode='decimal'
        id={id}
        name={name}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        disabled={disabled}
        value={getRenderValue()}
        ref={ref}
        className={classNames('Input__input w-100', {
          'Input__input--error': !!error,
        })}
        data-testid={name}
      />
      {error && <div className='Input__error text-end'>{error}</div>}
    </div>
  );
};

export default CurrencyInput;

import { format } from "date-fns";
import jwt_decode from "jwt-decode";

export function lockScroll(isLocked: boolean) {
  document.querySelector("html")!.style.overflow = isLocked ? "hidden" : "";
}

export const formatNumber = (
  price: number,
  options?: Intl.NumberFormatOptions
) => {
  return new Intl.NumberFormat("en", {
    ...(options
      ? {
          ...options,
          style: options.currency ? "currency" : options.style,
        }
      : {}),
  }).format(price);
};

export const isEmail = (value: string) =>
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
    value
  );

export function dataURLtoBlob(dataurl: string) {
  var arr = dataurl.split(","),
    mime = arr[0]!.match(/:(.*?);/)![1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export async function getDataUrl(file: File) {
  const res: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = function () {
        return resolve(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    }
  );

  return res;
}
export async function srcToDataURI(src: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function (e) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      (context as any).drawImage(e.target, 0, 0);

      console.log(
        "🚀 ~ file: helpers.ts ~ line 64 ~ returnawaitnewPromise ~ canvas.toDataURL()",
        canvas.toDataURL()
      );
      resolve(canvas.toDataURL("image/svg+xml"));
    };
    image.src = src;
  });
}

export const normalizeDateBE = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

// export const normalizeDateBE = (date: Date) =>
//   format(date, "yyyy-MM-dd'T'HH:mm:ss+00:00");

export const isDataURI = (v: string) => {
  return v.startsWith("data:");
};

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getEmailFromToken = (token: string) => {
  if (!token) return "";
  let decodedToken = { email: "" };
  decodedToken = jwt_decode(token);

  if (decodedToken.email.length > 0) {
    return decodedToken.email;
  } else {
    return "";
  }
};

import { useState } from "react";
import styled from "styled-components";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
  TelegramIcon,
  LinkedinIcon,
  EmailIcon,
  RedditIcon,
} from "react-share";

/** Presentational */
import LinkButton from "components/LinkButton";
import Modal from "components/Modal";
import { useAppSelector } from "common/src/hooks";
import mixpanel from "utils/mixpanel"
import { ReactComponent as Plan } from 'common/src/icons/sidebar/plan.svg';

export default function WantMore() {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const user = useAppSelector((s) => s.user.user);
  const shareURL = `https://app.dianemoney.com?referrer_code=${user.referrer_code}`;
  const shareText = `I’m reaching my goals faster with Diane Money! Join me today!`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareURL);
  }

  return (
    <div
      className="mt-n3 text-center bg-wantmore mx-n3"
      style={{
        paddingTop: 150,
        paddingBottom: 100,
      }}
    >
      <Title className="fw-800 text-center mb-3">Loving Diane’s Help?</Title>
      <Text className="text-gray text-center mb-3 pb-3" style={{lineHeight: 1.3}}>
        Every month you keep using the app, you get entered into our milestone giveaways.<br />
        When you share this with your friends to use the app, you get more entries<br />
        When we grow our user base to 100,000 users, one of you will win a $30,000 USD vacation!
      </Text>
      <LinkButton className="mx-auto" onClick={() => { console.log("Share Diane"); setIsShareModalOpen(true); mixpanel.track("Share Diane") }}>
        Share the app today
      </LinkButton>
      <Modal
        isOpened={isShareModalOpen}
        closeModal={() => setIsShareModalOpen(false)}
      >
        <div className="d-flex flex-column align-items-center pt-3">
          <CategoryTitle className="mb-4 fw-800 text-center">
            Share Diane to your socials
          </CategoryTitle>
          <InputGroup className="d-none d-lg-flex">
            <GroupInput type="text" value={shareURL} />
            <GroupButton className="btn btn-outline-secondary" type="button" onClick={copyToClipboard}>
              <Plan style={{ height: 30, marginTop: 5 }} />
            </GroupButton>
          </InputGroup>
          <div className="container d-flex w-100  justify-content-center">
            <div className="mx-2">
              <TwitterShareButton title={shareText} url={shareURL}>
                <TwitterIcon size={40} round={true} />
              </TwitterShareButton>
            </div>
            <div className="mx-2">
              <FacebookShareButton quote={shareText} url={shareURL}>
                <FacebookIcon size={40} round={true} />
              </FacebookShareButton>
            </div>
            <div className="mx-2">
              <WhatsappShareButton title={shareText} url={shareURL}>
                <WhatsappIcon size={40} round={true} />
              </WhatsappShareButton>
            </div>
            <div className="mx-2">
              <TelegramShareButton title={shareText} url={shareURL}>
                <TelegramIcon size={40} round={true} />
              </TelegramShareButton>
            </div>
            <div className="mx-2">
              <LinkedinShareButton title={shareText} url={shareURL}>
                <LinkedinIcon size={40} round={true} />
              </LinkedinShareButton>
            </div>
            <div className="mx-2">
              <RedditShareButton title={shareText} url={shareURL}>
                <RedditIcon size={40} round={true} />
              </RedditShareButton>
            </div>
            <div className="mx-2">
              <EmailShareButton subject={'Join me today!'} body={shareText} url={shareURL}>
                <EmailIcon size={40} round={true} />
              </EmailShareButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const Title = styled.div`
  font-size: 38px;
  line-height: 37px;
`;
const Text = styled.div`
  font-size: 18px;
  line-height: 17px;
`;

const CategoryTitle = styled.div`
  font-size: 24px;
  line-height: 37px;
`;

const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  max-width: 350px;
  margin-bottom: 1.5rem;
`;

const GroupInput = styled.input`
  background-color: #fff;
  border: 1px solid #ebeaeb;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 13px 20px;
  color: #8d89a3;
  outline: none;
  transition: border-color 0.25s;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  flex-grow: 1;
  cursor: pointer;
  padding-right: 0px;
`;

const GroupButton = styled.button`
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
  border: 0px;
`;

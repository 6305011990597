import { Helmet } from "react-helmet";
import Tile from "components/Tile/Tile";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "common/src/icons/goals/plus.svg";
import { ReactComponent as ShareIcon } from "common/src/icons/share.svg";
import { ReactComponent as PencilIcon } from "common/src/icons/pencil.svg";
import image from "../../images/goal.png";
import image4 from "../../images/goal4.png";
import image2 from "common/src/icons/goals/house.svg";
import image3 from "common/src/icons/goals/money.svg";
import { ButtonIcon, ButtonOutlineIcon } from "components-styled/Button";
import WantMore from "./WantMore";
import Empty from "./Empty";
import { addMonths, format } from "date-fns";
import debtImage from "common/src/icons/debt.svg";
import { useState } from "react";
import { capitalize, formatNumber } from "helpers";
import LinkButton from "components/LinkButton";
import { Col, Row } from "components-styled/Grid";
import { Link, useNavigate } from "react-router-dom";
import { RoutesEnum } from "pages/DashboardRoutes";
import { useModal } from "hooks/useModal";
import DashboardModal from "./DashboardModal";
import { useAppDispatch, useAppSelector } from "common/src/hooks";
import { useEffect } from "react";
import { updateUserDetails } from "common/src/store/user/userSlice";
import { Debt, Goal } from "common/src/types";
import apiClient from "core/apiClient";
import { updateGoals, setEditGoal } from "common/src/store/goals/goalsSlice";
import { updateDebts, setEditDebt } from "common/src/store/debts/debtsSlice";
import ShareGoal from "./ShareGoal";
import mixpanel from "utils/mixpanel"

export default function Dashboard() {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [expandedId, setExpandedId] = useState<string>();
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [editingDebt, setEditingDebt] = useState<Debt>();
  const [shareGoal, setShareGoal] = useState({ amount: 0, name: "" });
  const isFresh = useAppSelector((s) => s.user.fresh);
  const user = useAppSelector((s) => s.user.user);
  let goals = useAppSelector((s) => s.goals.goals);
  const debts = useAppSelector((s) => s.debts.debts);
  const { isModalOpened, closeModal } = useModal(isFresh);

  goals = [...goals].sort(
    (a, b) => Number(b.is_primary) - Number(a.is_primary)
  );

  const onExpand = (id: string) => () => {
    setExpandedId((p) => (p === id ? undefined : id));
  };

  useEffect(() => {
    if (!user.email && !user.id) {
      apiClient
        .getUser()
        .then((res) => {
          dispatch(
            updateUserDetails({
              email: res.email,
              id: res.id,
              referrer_code: res.referrer_code,
              referal_number: res.referal_number,
              is_waitlisted: res.is_waitlisted,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (editingDebt) {
      dispatch(setEditDebt(editingDebt));
      navigate(RoutesEnum.editDebt);
    }
  }, [editingDebt]);

  useEffect(() => {
    if (!debts.length) {
      apiClient
        .getDebts()
        .then((res) => {
          dispatch(updateDebts(res));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [debts]);

  const setGoalsAsPrimary = (id: number) => {
    apiClient
      .patchGoal({
        id,
        is_primary: true,
      })
      .then((res) => {
        if (Array.isArray(res)) {
          dispatch(updateGoals(res));
        }
      })
      .catch(console.error);
  };

  return (
    <>
      <Helmet>
        <title>Diane Money | Goals</title>
      </Helmet>

      <Container className="w-100 mx-auto">
        <div className="mb-4">
          <Title className="fw-800 text-gray">Goals </Title>
          <Text className="text-gray">| {format(new Date(), "MMMM")}</Text>
        </div>
        <div
          style={{
            borderBottom: "2px solid #D1CFDC",
            paddingBottom: 100,
            marginBottom: 100,
          }}
        >
          {goals.length ? (
            goals.map((g, i) => {
              return (
                <Tile
                  id={g.id}
                  amount={+(g.total_amount || 0) + +(g.saving || 0)}
                  total={+(g.amount || 0)}
                  small={!g.is_primary}
                  className="mb-4"
                  star={g.is_primary}
                  key={g.id}
                  image={g.image_presigned_url}
                  title={g.name}
                  onExpand={onExpand(`goal-${g.id}`)}
                  isExpanded={`goal-${g.id}` === expandedId}
                  onSetGoalAsPrimary={setGoalsAsPrimary}
                  content={[
                    {
                      label: "Money Saved",
                      color: "green",
                      value: formatNumber(g.contribution ?? 0, {
                        currency: "usd",
                      }),
                    },
                    {
                      label: "Remaining",
                      color: "purple",
                      value: formatNumber(g.amount, {
                        currency: "usd",
                      }),
                    },
                    {
                      label: "Contribution",
                      value: formatNumber(g.contribution ?? 0, {
                        currency: "usd",
                      }),
                      badge: "per month",
                    },
                  ]}
                  btns={
                    <Row className="row row-cols-auto justify-content-center align-items-center gx-4">
                      <Col
                        className="col w-100"
                        style={{
                          maxWidth: 298,
                        }}
                      >
                        <ButtonIcon
                          className="d-flex align-items-center justify-content-center w-100"
                          onClick={(event) => {
                            console.log("Adasdad");
                            mixpanel.track("Share Goal")
                            setIsShareOpen(true);
                            setShareGoal(g);
                          }}
                        >
                          <ShareIcon /> <span className="ms-2">Share</span>
                        </ButtonIcon>
                      </Col>
                      <Col
                        className="col w-100"
                        style={{
                          maxWidth: 298,
                        }}
                      >
                        <ButtonOutlineIcon
                          onClick={() => {
                            navigate(RoutesEnum.editGoal);
                            dispatch(setEditGoal(g));
                          }}
                          className="d-flex align-items-center justify-content-center w-100"
                        >
                          <PencilIcon /> <span className="ms-2">Edit Goal</span>
                        </ButtonOutlineIcon>
                      </Col>
                      <Col>
                        <LinkButton
                          onClick={() => {
                            setIsDeleting(true)
                            apiClient
                              .deleteGoal(g.id)
                              .then((res) => {
                                dispatch(updateGoals(res));
                              })
                              .catch((err) => {
                                console.log(
                                  "🚀 ~ file: Dashboard.tsx ~ line 244 ~ apiClient.deleteGoal ~ err",
                                  err
                                );
                              }).finally(() => {
                                setIsDeleting(false)
                              });
                          }}
                        >
                          {isDeleting ? (
                            <div className="spinner-border text-primary">
                              <span className="visually-hidden">Deleting...</span>
                            </div>
                          ) : 'Delete Goal'}
                        </LinkButton>
                      </Col>
                      {
                        <Col className="col-12 pt-4" >
                          <GoalSuggestion>
                            Your Plan can help you achieve your goal {g.report.reduced_months} months faster. <Link to="/pro-plan">Upgrade to Pro</Link> free for 14 days to get started.</GoalSuggestion>
                        </Col>
                      }
                    </Row>
                  }
                />
              );
            })
          ) : (
            <Empty
              icon={<img src={debtImage} alt="debt-empty" />}
              title="You have no current goals to show!"
              text="Add a goal to start working towards it!"
            />
          )}
          <ButtonIcon
            className="d-flex align-items-center justify-content-center mx-auto"
            style={{
              marginTop: 100,
              width: "100%",
              maxWidth: 290,
            }}
            onClick={() => {
              navigate(RoutesEnum.createGoal);
            }}
          >
            <PlusIcon /> <span className="ms-2">Add a Goal</span>
          </ButtonIcon>
        </div>
        <div className="mb-4">
          <Title className="fw-800 text-gray">Debts </Title>
          <Text className="text-gray">| {format(new Date(), "MMMM")}</Text>
        </div>
        {debts.length ? (
          debts.map((d, i) => {
            return (
              <Tile
                id={d.id}
                isDebt={true}
                amount={d.total_paid}
                total={d.total_amount}
                small
                className="Tile--debt mb-4"
                key={d.id}
                title={d.name}
                star={false}
                onExpand={onExpand(`debt-${d.id}`)}
                isExpanded={`debt-${d.id}` === expandedId}
                content={[
                  {
                    label: "Debt Type",
                    value: d.type ? capitalize(d.type) : d.type,
                  },
                  {
                    label: "Interest Rate",
                    color: "red",
                    value: `${d.interest_rate}%`,
                  },
                  {
                    label: "Monthly Payment",
                    value: formatNumber(d.monthly_payment, {
                      currency: "usd",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                    badge: `${Number(120).toFixed(2).slice(-3)}`,
                    badgeTop: true,
                  },
                ]}
                btns={
                  <Row className="row row-cols-auto justify-content-center align-items-center gx-4">
                    <Col
                      className="col w-100"
                      style={{
                        maxWidth: 298,
                      }}
                    >
                      <ButtonOutlineIcon
                        onClick={(event) => {
                          event.preventDefault();
                          setEditingDebt(d);
                        }}
                        className="d-flex align-items-center justify-content-center w-100"
                      >
                        <PencilIcon /> <span className="ms-2">Edit Debt</span>
                      </ButtonOutlineIcon>
                    </Col>
                    <Col>
                      <LinkButton
                        onClick={() => {
                          apiClient
                            .deleteDebt(d.id)
                            .then((res) => {
                              dispatch(updateDebts(res));
                            })
                            .catch((err) => {
                              console.log(
                                "🚀 ~ file: Dashboard.tsx ~ line 347 ~ apiClient.deleteDebt ~ err",
                                err
                              );
                            });
                        }}
                      >
                        Delete Debt
                      </LinkButton>
                    </Col>
                    <Col className="col-12 pt-4" >
                      <GoalSuggestion>
                        {d.report.statements.statement_1}
                      </GoalSuggestion>
                    </Col>
                  </Row>
                }
              />
            );
          })
        ) : (
          <Empty
            icon={<img src={image3} alt="debt-empty" />}
            title="Looks like you’re debt-free!"
            text="Not the case yet? Add a debt to start chipping it away!"
          />
        )}
        <div className="mb-5 pb-5">
          <ButtonIcon
            className="d-flex align-items-center justify-content-center mx-auto"
            style={{
              marginTop: 100,
              width: "100%",
              maxWidth: 290,
            }}
            onClick={() => {
              navigate(RoutesEnum.createDebt);
            }}
          >
            <PlusIcon /> <span className="ms-2">Add a Debt</span>
          </ButtonIcon>
        </div>
      </Container>
      <ShareGoal
        isOpen={isShareOpen}
        toggleOpen={() => setIsShareOpen(false)}
      />
      <WantMore />
      <DashboardModal isOpened={isModalOpened} closeModal={closeModal} />
    </>
  );
}

export const Container = styled.div`
  max-width: 900px;
  padding-top: 90px;
`;

export const Title = styled.span`
  font-size: 40px;
  line-height: 51px;
`;

export const Text = styled.span`
  font-size: 40px;
  line-height: 37px;
`;

const GoalSuggestion = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #8D89A3;
  a {
    color: #8D89A3;
    text-decoration: underline;
  }
`;

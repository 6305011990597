import "./YourPlan.scss";
import { Helmet } from "react-helmet";
import { useTabs } from "common/src/hooks/useTabs";
import { ReactComponent as LikeIcon } from "common/src/icons/like.svg";
import { ReactComponent as SearchIcon } from "common/src/icons/search.svg";
import { ReactComponent as TipIcon } from "common/src/icons/tip.svg";
import { ReactComponent as RecommendationsIcon } from "common/src/icons/recommendations.svg";
import { ReactComponent as PositivesIcon } from "common/src/icons/like-lg.svg";
import { ReactComponent as RoomToGrowIcon } from "common/src/icons/search-lg.svg";
import { Text, Title } from "pages/Dashboard/Dashboard";
import { format } from "date-fns";
import styled from "styled-components";
import classNames from "classnames";
import WantMore from "pages/Dashboard/WantMore";
import Checkbox from "components/Checkbox/Checkbox";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "common/src/hooks";
import apiClient from "core/apiClient";
import { updateReports } from "common/src/store/reports/reportsSlice";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { formatNumber } from "helpers";
import WaitListed from "./WaitListed";
import { updateUserDetails } from "common/src/store/user/userSlice";

import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";


type TabType = "The Positives" | "Room to Grow" | "Recommendations";

interface Tab {
  label: TabType;
  icon: JSX.Element;
}

const TABS: Tab[] = [
  {
    label: "The Positives",
    icon: <LikeIcon />,
  },
  {
    label: "Room to Grow",
    icon: <SearchIcon />,
  },
  {
    label: "Recommendations",
    icon: <TipIcon />,
  },
];

export default function YourPlan() {
  const { activeTab, onTabClick } = useTabs("The Positives");
  const [activeSteps, setActiveSteps] = useState<string[]>(["step-1"]);
  const reports = useAppSelector((s) => s.reports.reports);
  const user = useAppSelector((s) => s.user.user);
  const [isLoading, setIsLoading] = useState<boolean>(
    reports.well.length === 0 ? true : false
  );
  const dispatch = useAppDispatch();
  const { well, recommendations2: recommendations, improvement } = reports;
  const goals = useAppSelector((s)=>s.goals.goals)

  useEffect(() => {
    if (!user.email && !user.id) {
      apiClient
        .getUser()
        .then((res) => {
          dispatch(
            updateUserDetails({
              email: res.email,
              id: res.id,
              referrer_code: res.referrer_code,
              referal_number: res.referal_number,
              is_waitlisted: res.is_waitlisted,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (!reports.well.length) {
      loadData()
    }
  }, []);

  const loadData = () => {
    apiClient
      .getReport()
      .then((res) => {
        dispatch(updateReports(res));
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
        setIsLoading(false);
      });
  }

  const setIsTakingAction = (item: any) => {
    apiClient
      .setIsTakingReportAction(item.id)
      .then((res) => {
        loadData();
        console.log("🚀 ~ file: YourPlan.tsx ~ line 58 ~ .then ~ res", res);
      })
      .catch((err) => {

        console.log(err);
      });
  };

  const renderTab = () => {
    switch (activeTab) {
      case "Recommendations":
        return (
          <div>
            <RecommendationsIcon className="mb-2" />
            <TabTitle className="mb-2 fw-800">Our Recommendations</TabTitle>
            <div className="mb-4 pb-2 text-gray">
              Diane has created three personalized options to help you reach
              your goals faster so that way you can have a better relationship
              with your money. Choose which option you want to take action on
              and Diane will update your personalized plan for you!
            </div>
            {recommendations.map((item, index) => (
              <React.Fragment key={item.id}>
                <div className="mb-4 pb-2">
                  <TabPoint className="text-dark fw-800">
                    Step {index + 1}
                  </TabPoint>
                  <div className="text-gray mb-3">{item.msg}</div>
                  <Checkbox
                    label="This is helpful!"
                    className="mb-4"
                    checked={item.in_action!}
                    onChange={() => setIsTakingAction(item)}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        );

      case "Room to Grow":
        return (
          <div>
            <RoomToGrowIcon className="mb-2" />
            <TabTitle className="mb-2 fw-800">Where you can do better</TabTitle>
            <div className="mb-4 pb-2 text-gray">
              Here is where Diane thinks you can have improvements with your
              money. Who doesn’t want improvements?!?
            </div>
            {improvement.map((item, index) => (
              <React.Fragment key={item}>
                <TabPoint className="text-red fw-800">
                  {formatNumber(parseFloat(item), {
                    currency: "usd",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </TabPoint>
                <div className="text-gray mb-4 pb-2">
                  {item.replace(/[+-]?([0-9]*[.])?[0-9]+/, "")}
                </div>
              </React.Fragment>
            ))}
          </div>
        );

      case "The Positives":
      default:
        return (
          <div>
            <PositivesIcon className="mb-2" />
            <TabTitle className="mb-2 fw-800">You’re Doing Great</TabTitle>
            <div className="mb-4 pb-2 text-gray">
            {goals.length>=1?`It's great that you have financial goals! We love your ${goals[0].name} goal. We can't wait to help you reach it faster`:
            `We're so excited to help you on your financial journey. The first step toward financial independence is having a plan. That you're here is worthy of celebration`}
            </div>
            {well.map((item, i) => (
              <React.Fragment key={item}>
                <TabPoint className="text-green fw-800">{i + 1}</TabPoint>
                <div className="text-gray mb-4 pb-2">
                  {item}
                </div>
              </React.Fragment>
            ))}
          </div>
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>Diane Money | Your Plan</title>
      </Helmet>
      <CircleSpinnerOverlay
        loading={isLoading}
        overlayColor="rgba(16, 8, 64, 0.2)"
        color="rgb(16, 8, 64)"
      />
      <Container className="w-100 mx-auto">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="mb-4">
          <Title className="fw-800 text-gray">Plan</Title>
          <Text className="text-gray"> | {format(new Date(), "MMMM")}</Text>
        </div>
      </Container>
      {0 ? (
        <WaitListed />
      ) : (
        <Container className="w-100 mx-auto">
          <div className="row row-cols-auto gx-0 align-items-end">
            {TABS.map((tab) => (
              <div className="col px-0" key={tab.label}>
                <button
                  className={classNames(
                    "YourPlan__tab d-flex align-items-center cursor-pointer",
                    {
                      "YourPlan__tab--active": activeTab === tab.label,
                    }
                  )}
                  onClick={onTabClick(tab.label)}
                >
                  {tab.icon}
                  <span className="YourPlan__tabLabel ms-2">{tab.label}</span>
                </button>
              </div>
            ))}
          </div>
          <Content className="py-3 px-4 pb-5 mb-4">
            <div
              style={{
                maxWidth: 782,
              }}
            >
              {renderTab()}
            </div>{" "}
          </Content>
          <div className="row gx-2 row-cols-auto w-100 justify-content-center mb-5">
            {TABS.map((t) => (
              <div className="col" key={t.label}>
                <button
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                    onTabClick(t.label)();
                  }}
                  className={classNames(
                    "cursor-pointer YourPlan__circle border-0 px-0 rounded-circle",
                    {
                      "YourPlan__circle--active": activeTab === t.label,
                    }
                  )}
                />
              </div>
            ))}
          </div>
        </Container>
      )}
      <WantMore />
    </>
  );
}

export const Container = styled.div`
  max-width: 882px;
  padding-top: 90px;
`;
export const Content = styled.div`
  background: #ffffff;
  border: 1px solid #ebeaeb;
  border-radius: 0px 10px 10px 10px;
  /* padding-right: 70px; */
`;

const TabTitle = styled.div`
  font-size: 38px;
  line-height: 37px;
`;

const TabPoint = styled.span`
  font-size: 24px;
  line-height: 37px;
`;

import { useCallback, useEffect, useState } from "react";
import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOptions,
} from "react-plaid-link";

/** presentational */
import { Button } from "components-styled/Button";
import { WelcomeCard } from "components-styled/Card";

/** icons */
import { ReactComponent as Icon } from "common/src/icons/goals/money.svg";

/** containers */
import LoginContainer from "containers/LoginContainer";
import apiClient from "core/apiClient";
import { toast, ToastContainer } from "react-toastify";

interface Props {
  onClick: () => void;
}

export default function ConnectAccount({ onClick }: Props) {
  const [token, setToken] = useState<string | null>(null);
  const [debounce, setDebounce] = useState(false);
  // // get a link_token from your API when component mounts
  useEffect(() => {
    const createLinkToken = () => {
      setDebounce(true);
      apiClient
        .getLinkToken()
        .then((res) => {
          console.log(
            "🚀 ~ file: ConnectAccount.tsx ~ line 46 ~ .then ~ res",
            res
          );
          const { link_token } = res;
          setToken(link_token);
        })
        .catch((err) => {
          console.log(
            "🚀 ~ file: ConnectAccount.tsx ~ line 34 ~ apiClient.getLinkToken ~ err",
            err
          );
        });
    };

    if (debounce === false) {
      createLinkToken();
    }
  }, []);

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    console.log(publicToken, metadata);

    apiClient
      .setAccessToken(publicToken)
      .then((res) => {
        console.log(
          "🚀 ~ file: ConnectAccount.tsx ~ line 46 ~ .then ~ res",
          res
        );
        onClick();
        toast.success(
          "Request completed",
          {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: ConnectAccount.tsx ~ line 34 ~ apiClient.getLinkToken ~ err",
          err
        );
        toast.error(err.response.data.error_message, {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        onClick();
      });
  }, []);

  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    console.log(eventName, metadata);
  }, []);

  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    console.log(error, metadata);
  }, []);

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onEvent,
    onExit,
  };

  const {
    open,
    ready,
    // error,
    // exit
  } = usePlaidLink(config);

  return (
    <LoginContainer>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <WelcomeCard className="mx-auto d-flex flex-column align-items-center">
        <Icon />
        <div className="mt-2 mb-3 text--24 fw-800 text-uppercase">
          Connect Account
        </div>
        <div className="text-gray">
          <div
            className="mb-2 text-center"
            style={{
              maxWidth: 774,
            }}
          >
            Great news! Your goals are set and Diane is ready to start giving
            you monthly advice to help you conquer your goals. Diane is going to
            show you how many months it can take for you to reach your goals
            after you link your accounts. This is how Diane will be able to
            simplify your financial life for you.
          </div>
          <div className="mb-3 pb-3 text-center">
            Diane Money partners with Plaid and uses industry-standard security
            practices to keep your data safe. Read more about our security
            practices <a href="#" target="_blank" rel="noopener noreferrer" style={{ color: '#8D89A3', textDecoration: 'underline' }}>here</a>.
          </div>
        </div>
        <Button
          className="d-flex align-items-center justify-content-center"
          onClick={() => open()}
        >
          Link Bank Account
        </Button>
      </WelcomeCard>
    </LoginContainer>
  );
}

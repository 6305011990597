import { ReactComponent as Icon } from "common/src/icons/goal.svg";
import { ReactComponent as Arrow } from "common/src/icons/arrow-right.svg";
import { Button, ButtonOutline } from "components-styled/Button";
import { WelcomeCard } from "components-styled/Card";
import LoginContainer from "containers/LoginContainer";

interface Props {
  title?: string;
  subTitle?: string;
  addBtnLabel?: string;
  onClickAdd: () => void;
  onClickNext: () => void;
  icon?: JSX.Element;
}

export default function ItemCreated({
  title = "Great work Setting a Goal",
  subTitle = "",
  onClickAdd,
  onClickNext,
  icon = <Icon />,
  addBtnLabel = "Add Another Goal",
}: Props) {
  return (
    <LoginContainer>
      <WelcomeCard className="mx-auto d-flex flex-column align-items-center">
        {icon}
        <div className="mt-2 mb-3 text--24 fw-800 text-uppercase">{title}</div>
        <div className="text-gray">
          <div
            className="mb-5 text-center"
            style={{
              maxWidth: 754,
            }}
          >
            {subTitle}
          </div>
        </div>
        <div className="row gx-3 row-cols-auto">
          <div className="col">
            <ButtonOutline onClick={onClickAdd}>{addBtnLabel}</ButtonOutline>
          </div>
          <div className="col">
            <Button
              className="d-flex align-items-center justify-content-center"
              onClick={onClickNext}
            >
              <span className="me-2">Next Step</span> <Arrow />
            </Button>
          </div>
        </div>
      </WelcomeCard>
    </LoginContainer>
  );
}

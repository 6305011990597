import classNames from 'classnames';
import {
  ChangeEventHandler,
  FocusEventHandler,
  InputHTMLAttributes,
} from 'react';
import './Input.scss';

interface Props {
  value: string;
  type?: 'text' | 'password' | 'number';
  onChange: ChangeEventHandler;
  name: string;
  label?: string;
  className?: string;
  required?: boolean;
  error?: boolean | string | undefined;
  onBlur?: FocusEventHandler;
  placeholder?: string;
}

export default function Input({
  value,
  type = 'text',
  onChange,
  onBlur,
  name,
  label,
  className = ' ',
  required = false,
  error,
  placeholder,
  ...rest
}: Props & InputHTMLAttributes<HTMLInputElement>) {
  return (
    <div className={`Input ${className}`}>
      {label && <div className='Input__label fw-bold mb-2'>{label}</div>}
      <div className='d-flex'>
        <div className='flex-grow-1'>
          <input
            value={value}
            onChange={onChange}
            type={type}
            className={classNames('Input__input w-100', {
              'Input__input--error': error,
            })}
            name={name}
            onBlur={onBlur}
            placeholder={placeholder}
            {...rest}
          />
        </div>
        {required && (
          <div
            style={{
              paddingLeft: 9,
              color: !!error ? '#ee465a' : undefined,
            }}
          >
            *
          </div>
        )}
      </div>
      {error && <div className='Input__error text-end'>{error}</div>}
    </div>
  );
}

import styled from 'styled-components';

interface Props {
  icon: JSX.Element;
  title: string;
  text: string;
}

export default function Empty({ icon, title, text }: Props) {
  return (
    <Container className='d-flex flex-column align-items-center pb-4'>
      <div className='mb-2'>{icon}</div>
      <Title className='mb-2 fw-800'>{title}</Title>
      <Text className='text-gray'>{text}</Text>
    </Container>
  );
}

const Container = styled.div`
  img {
    width: 244px;
    height: 244px;
  }
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 37px;
`;

const Text = styled.div`
  font-size: 18px;
  line-height: 17px;
`;

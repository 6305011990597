import { PropsWithChildren } from "react";
import Footer from "../components/Footer/Footer";

export default function LoginContainer({ children, footer = true }: PropsWithChildren<{footer?: boolean}>) {
  return (
    <div className="d-flex flex-column min-vh-100 bg">
      <div className="flex-grow-1 pt-5 pb-5 mb-3">{children}</div>
      { footer && <Footer /> }
    </div>
  );
}
